import { DateTime, Duration } from 'luxon'


export function formatDateTime(dateTime: string, language: string): string {
  return new Intl.DateTimeFormat(language, {
    dateStyle: 'medium',
    timeStyle: 'short'
  }).format(new Date(dateTime))
}

export function formatDateTime2Digit(dateTime: string, locale: string): string {
  return DateTime.fromISO(dateTime).reconfigure({ locale }).toLocaleString(DateTime.DATETIME_SHORT)
}

export function formatDateTimeRange(dateTime: string, locale: string) {
  const { days, hours } = DateTime.fromISO(dateTime).diffNow().shiftTo('days', 'hours', 'milliseconds').toObject()

  return Duration.fromObject({ days, hours }).reconfigure({ locale }).toHuman()
}
