import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { accountAPI, type Account } from '~/api/account'
import { AlertBox } from '~/components/ui/alert-box'
import { Button } from '~/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { Subheading, Text } from '~/components/ui/typography'
import { mutationError } from '~/lib/mutation-error'


interface FormInputs {
  email: string
  firstName: string
  lastName: string
}

export default function ProfileSection() {
  const { t } = useTranslation([ 'settings', 'general' ])
  const queryClient = useQueryClient()

  // Fetch account
  const { data: account } = useSuspenseQuery({
    queryKey: [ 'account' ],
    queryFn: () => accountAPI.getSelf()
  })

  // Form state
  const form = useForm<FormInputs>({
    defaultValues: {
      email: account.email,
      firstName: account.firstName,
      lastName: account.lastName
    }
  })

  // Update profile
  const mutation = useMutation({
    mutationKey: [ 'account', 'settings', 'profile' ],
    mutationFn: (inputs: FormInputs) => accountAPI.update(account.id, {
      firstName: inputs.firstName,
      lastName: inputs.lastName
    }),
    onSuccess: ({ account }) => {
      queryClient.setQueryData<Account>([ 'account' ], account)
      toast.success(t('profile.success'))
    }
  })

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
      <div>
        <Subheading>{t('profile.title')}</Subheading>
        <Text>{t('profile.description')}</Text>
      </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(data => mutation.mutate(data))}
          className="space-y-6 sm:maw-w-xl"
        >

          {mutation.error && (
            <AlertBox
              error
              title={mutationError(mutation.error, {
                default: t('profile.errors.default')
              })}
            />
          )}

          <FormField
            name="email"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('profile.email')}</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    disabled
                    required
                    autoFocus
                    autoComplete="email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="w-full flex flex-row gap-8">

            <FormField
              name="firstName"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('profile.first_name')}</FormLabel>
                  <FormControl>
                    <Input
                      required
                      maxLength={64}
                      autoComplete="given-name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="lastName"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('profile.last_name')}</FormLabel>
                  <FormControl>
                    <Input
                      required
                      maxLength={64}
                      autoComplete="family-name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

          </div>

          <Button type="submit" disabled={mutation.isPending}>
            {t('profile.submit')}
          </Button>

        </form>
      </Form>

    </div>
  )
}
