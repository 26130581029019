import { ClipboardDocumentIcon, EllipsisVerticalIcon, EyeIcon, PlusIcon } from '@heroicons/react/20/solid'
import { FolderPlusIcon } from '@heroicons/react/24/outline'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { type Namespace, type NamespaceExpanded } from '~/api/namespace'
import { organizationAPI } from '~/api/organization'
import { EmptyState } from '~/components/empty-state'
import { Head } from '~/components/head'
import { Avatar, AvatarFallback } from '~/components/ui/avatar'
import { Button } from '~/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from '~/components/ui/dropdown-menu'
import { Separator } from '~/components/ui/separator'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '~/components/ui/table'
import { Heading, Subheading, Text } from '~/components/ui/typography'
import { copy } from '~/lib/copy'
import { formatDateTime2Digit } from '~/lib/datetime'
import { initials } from '~/lib/utils'


export default function CustomersPage() {
  const { t, i18n } = useTranslation([ 'customers' ])
  const queryClient = useQueryClient()
  const params = useParams() as { organization: string }

  // Fetch the customers
  const { data: { namespaces: customers, totalCount } } = useSuspenseQuery({
    queryKey: [ 'organizations', params.organization, 'customers' ],
    queryFn: () => organizationAPI.listNamespaces(params.organization, {}),
    select: data => {
      data.namespaces.forEach(customer => {
        queryClient.setQueryData<NamespaceExpanded>(
          [ 'organizations', params.organization, 'customers', customer.id ],
          customer
        )
      })

      return data
    }
  })

  // Copy customer id and notify
  function copyCustomerId(customer: Namespace) {
    copy(customer.shortId)
    toast.info(t('view_customer.id_copied'))
  }

  return (
    <div className="flex grow flex-col gap-4 items-start">

      <Head title={t('customer_list.page_title')} />

      <Outlet />

      <div className="flex w-full items-end justify-between gap-4">
        <Heading>{t('customer_list.header_title')}</Heading>
        <Link to="./new">
          <Button>
            <PlusIcon />
            {t('customer_list.action_new_customer')}
          </Button>
        </Link>
      </div>

      <Text>{t('customer_list.header_description')}</Text>

      <Separator />

      {totalCount === 0 ? (
        <EmptyState
          title={t('customer_list.empty_title')}
          description={t('customer_list.empty_description')}
          Icon={FolderPlusIcon}
        >
          <Link to="./new">
            <Button>
              <PlusIcon />
              {t('customer_list.action_new_customer')}
            </Button>
          </Link>
        </EmptyState>
      ) : (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t('fields.name')}</TableHead>
                <TableHead>{t('fields.id')}</TableHead>
                <TableHead>{t('fields.created_at')}</TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody>
              {customers.map(customer => (
                <TableRow key={customer.id}>
                  <TableCell className="font-medium">
                    <div className="flex flex-row items-center gap-4">
                      <Avatar>
                        <AvatarFallback>{initials(customer.name)}</AvatarFallback>
                      </Avatar>
                      <Link to={`./${customer.shortId}`} state={{ name: customer.name }}>
                        <Subheading>{customer.name}</Subheading>
                      </Link>
                    </div>
                  </TableCell>
                  <TableCell>
                    {customer.shortId}
                  </TableCell>
                  <TableCell>
                    {formatDateTime2Digit(customer.createdAt, i18n.language)}
                  </TableCell>
                  <TableCell className="text-right flex flex-row justify-end">
                    <Link to={`./${customer.shortId}`} state={{ name: customer.name }}>
                      <Button variant="ghost" className="mr-4">
                        {t('customer_list.view_button')}
                      </Button>
                    </Link>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="icon">
                          <EllipsisVerticalIcon />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuLabel>{t('customer_list.menu.label')}</DropdownMenuLabel>
                        <Link to={`./${customer.shortId}`} state={{ name: customer.name }}>
                          <DropdownMenuItem>
                            <EyeIcon className="text-tertiary" />
                            {t('customer_list.menu.view')}
                          </DropdownMenuItem>
                        </Link>
                        <DropdownMenuItem onClick={() => copyCustomerId(customer)}>
                          <ClipboardDocumentIcon className="text-tertiary" />
                          {t('customer_list.menu.copy_id')}
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}

    </div>
  )
}
