import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { accountAPI } from '~/api/account'
import { AlertBox } from '~/components/ui/alert-box'


export default function VerifyEmailSection() {
  const { t } = useTranslation([ 'settings' ])

  // Fetch account
  const { data: account } = useSuspenseQuery({
    queryKey: [ 'account' ],
    queryFn: () => accountAPI.getSelf()
  })

  // Re-send email verification email
  const mutation = useMutation({
    mutationKey: [ 'account', 'settings', 'resend-verification-email' ],
    mutationFn: () => accountAPI.resendVerificationEmail(account.id),
    onSuccess: () => toast.success(t('verify_email.success')),
    onError: () => toast.error(t('verify_email.errors.default'))
  })

  // Do not render if the account's email address has already been verified
  if (account.emailVerified) return null

  return (
    <AlertBox
      warning
      title={t('verify_email.title')}
      description={t('verify_email.description')}
      className="mt-12"
      buttons={[
        {
          title: t('verify_email.resend_button'),
          onClick: () => mutation.mutate(),
          disabled: mutation.isPending
        }
      ]}
    />
  )
}
