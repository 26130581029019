import React, { ReactNode } from 'react'
import { Subheading, Text } from '~/components/ui/typography'


export interface EmptyStateProps {
  title: string
  description: string
  Icon: React.ForwardRefExoticComponent<React.PropsWithoutRef<React.SVGProps<SVGSVGElement>>>
  children?: ReactNode
}

export function EmptyState({ Icon, ...props }: EmptyStateProps) {
  return (
    <div className="grow flex flex-col w-full">
      <div className="basis-1/3" />
      <div className="basis-1/3 flex flex-col items-center">
        {<Icon className="mx-auto h-12 w-12 text-tertiary" strokeWidth={1} />}
        <Subheading className="mt-2">{props.title}</Subheading>
        <Text className="mt-2">{props.description}</Text>
        <div className="mt-6">
          {props.children}
        </div>
      </div>
      <div className="basis-1/3" />
    </div>
  )
}
