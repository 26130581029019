import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import { Settings } from 'luxon'
import { StrictMode } from 'react'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from 'sonner'
import { config } from '../config.ts'
import './index.css'
import './lib/i18n.ts'
import { ThemeProvider } from './components/theme-provider.tsx'
import { router } from './router.tsx'
import { AxiosError } from 'axios'


Settings.throwOnInvalid = true

Sentry.init({ dsn: config.sentry.dsn })

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, // 1 minute
      throwOnError: true,
      retry: (_failureCount, error) => {
        const isClientError = error instanceof AxiosError
          && error.status
          && 400 <= error.status
          && error.status <= 500

        return !isClientError
      }
    }
  }
})

createRoot(document.querySelector('#root')!).render((
  <StrictMode>
    <Sentry.ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <QueryErrorResetBoundary>
          <ThemeProvider defaultTheme="system" storageKey="cleverev-hub-ui-theme">
            <RouterProvider router={router} />
            <Toaster />
          </ThemeProvider>
        </QueryErrorResetBoundary>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>
))
