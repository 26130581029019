import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Head } from '~/components/head'
import { Button } from '~/components/ui/button'
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from '~/components/ui/card'
import { useAuth } from '~/hooks/use-auth'


export default function InvitationInvalidPage() {
  const { t } = useTranslation([ 'general' ])
  const { account } = useAuth()

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">

      <Head title={t('invitation.invalid.page_title')} />

      <Card className="sm:mx-auto sm:w-full sm:max-w-sm">
        <CardHeader>
          <CardTitle>{t('invitation.invalid.title')}</CardTitle>
          <CardDescription>{t('invitation.invalid.description')}</CardDescription>
        </CardHeader>
        <CardFooter>
          <Link to={account ? '/' : '/login'} className="w-full">
            <Button className="w-full">
              {t('invitation.to_home')}
            </Button>
          </Link>
        </CardFooter>
      </Card>

    </div>
  )
}
