import { api } from './api'
import type { Account } from './account'
import type { Organization } from './organization'


export type Membership = {
  id: string
  projectId: string
  organizationId: string
  accountId: string
  admin: boolean
  createdAt: string
  updatedAt: string
}

export type MembershipExpanded = Membership & {
  account: Account
  organization: Organization
}

class MembershipAPI {

  public async get(membershipId: string) {
    const result = await api.get<{ membership: MembershipExpanded }>(`/memberships/${membershipId}`)

    return result.data.membership
  }

  public async delete(membershipId: string) {
    await api.delete(`/memberships/${membershipId}`)
  }

  public async changeAccessLevel(membershipId: string, admin: boolean) {
    const result = await api.post<{ membership: Membership }>(`/memberships/${membershipId}/access`, { admin })

    return result.data
  }

}

export const membershipAPI = new MembershipAPI()
