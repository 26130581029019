import { useTranslation } from 'react-i18next'
import { useRouteError } from 'react-router-dom'
import { ErrorBoundary } from '~/components/error-boundary'
import { Head } from '~/components/head'
import { Button } from '~/components/ui/button'
import { Separator } from '~/components/ui/separator'
import { Heading, Text } from '~/components/ui/typography'


export default function PartnersError() {
  const { t } = useTranslation([ 'partners' ])
  const error = useRouteError()

  return (
    <div className="flex grow flex-col gap-4 items-start">

      <Head title={t('partner_list.page_title')} />

      <div className="flex w-full items-end justify-between gap-4">
        <Heading>{t('partner_list.header_title')}</Heading>
        <Button className="invisible" />
      </div>

      <Text>{t('partner_list.header_description')}</Text>

      <Separator />

      <ErrorBoundary error={error} />

    </div>
  )
}
