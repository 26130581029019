import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import {
  ArrowRightStartOnRectangleIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
  HomeIcon,
  LifebuoyIcon
} from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, Outlet, useMatch, useNavigate } from 'react-router-dom'
import { accountAPI } from '~/api/account'
import { type Organization } from '~/api/organization'
import { Avatar, AvatarFallback } from '~/components/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider
} from '~/components/ui/sidebar'
import { initials } from '~/lib/utils'


export default function NoOrganizationLayout() {
  const { t } = useTranslation([ 'general' ])
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  // Check for matching sidebar items
  const matchesHome = useMatch('/')
  const matchesSupport = useMatch('/support/*')

  // Fetch account
  const { data: account } = useSuspenseQuery({
    queryKey: [ 'account' ],
    queryFn: () => accountAPI.getSelf()
  })

  // Sign out
  const signOutMutation = useMutation({
    mutationKey: [ 'account', 'sign-out' ],
    mutationFn: () => accountAPI.signOut(),
    onSuccess: () => {
      queryClient.clear()
      navigate('/login')
    }
  })

  // Fetch the account's oldest membership
  const { data: { memberships: [ membership ] } } = useSuspenseQuery({
    queryKey: [ 'account', 'memberships', 'oldest' ],
    queryFn: () => accountAPI.listMemberships(account.id, {
      pagination: { take: 1 },
      sort: { by: 'createdAt' }
    }),
    select: data => {
      data.memberships.map(membership => (
        queryClient.setQueryData<Organization>(
          [ 'organizations', membership.organization.shortId ],
          membership.organization
        )
      ))

      return data
    }
  })

  // Navigate to the organization with the oldest membership
  if (membership) return (
    <Navigate to={`./${membership.organization.shortId}`} replace />
  )

  return (
    <SidebarProvider>

      <Sidebar variant="inset">

        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton size="lg" asChild>
                <Link to="">
                  <div className="flex aspect-square size-8 items-center justify-center rounded-lg">
                    <img
                      src="https://cleverev.net/assets/logo.svg"
                      alt={t('logo_alt')}
                      className="h-8 w-8"
                    />
                  </div>
                  <div className="grid flex-1 text-left text-sm leading-tight">
                    <span className="truncate font-semibold">{t('product_name')}</span>
                  </div>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>

        <SidebarContent>
          <SidebarGroup>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton asChild isActive={!!matchesHome}>
                  <Link to="">
                    <HomeIcon className="text-tertiary" />
                    <span className="font-medium">{t('sidebar.home')}</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroup>

          <SidebarGroup className="mt-auto">
            <SidebarGroupContent>
              <SidebarMenu>
                <SidebarMenuItem>
                  <SidebarMenuButton asChild isActive={!!matchesSupport}>
                    <Link to="/support">
                      <LifebuoyIcon className="text-tertiary" />
                      <span className="font-medium">{t('sidebar.support')}</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>

        <SidebarFooter>
          <SidebarMenu>
            <SidebarMenuItem>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <SidebarMenuButton
                    size="lg"
                    className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                  >
                    <Avatar className="h-8 w-8 rounded-lg">
                      <AvatarFallback className="rounded-lg bg-secondary">
                        {initials(`${account.firstName} ${account.lastName}`)}
                      </AvatarFallback>
                    </Avatar>
                    <div className="grid flex-1 text-left text-sm leading-tight">
                      <span className="truncate font-semibold">
                        {account.firstName} {account.lastName}
                      </span>
                      <span className="truncate text-xs">
                        {account.email}
                      </span>
                    </div>
                    <ChevronUpIcon className="text-tertiary ml-auto size-4" />
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                  side="bottom"
                  align="end"
                  sideOffset={4}
                >
                  <Link to="/settings/account">
                    <DropdownMenuItem>
                      <Cog6ToothIcon className="text-tertiary" />
                      {t('sidebar.settings')}
                    </DropdownMenuItem>
                  </Link>
                  <DropdownMenuItem onClick={() => signOutMutation.mutate()}>
                    <ArrowRightStartOnRectangleIcon className="text-tertiary" />
                    {t('sidebar.log_out')}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>

      </Sidebar>

      <SidebarInset>

        {/* <header className="flex h-16 shrink-0 items-center gap-2">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem className="hidden md:block">
                  <BreadcrumbLink href="#">
                    Building Your Application
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator className="hidden md:block" />
                <BreadcrumbItem>
                  <BreadcrumbPage>Data Fetching</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </div>
        </header> */}

        <main className="flex flex-1 flex-col gap-4 p-8 px-12">
          <Outlet />
        </main>

      </SidebarInset>
    </SidebarProvider>
  )
}
