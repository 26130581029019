import { api } from './api'
import type { ClientAccount } from './client-accounts'
import type { Organization } from './organization'


export type Namespace = {
  id: string
  shortId: string
  applicationId: string
  projectId: string
  name: string
  createdAt: string
  updatedAt: string
  suspended: boolean
  partnerId: string
  properties: unknown
}

export type NamespaceExpanded = Namespace & {
  partner: Organization
}

class NamespaceAPI {

  public getImpersonationURL(namespaceId: string) {
    return `${api.defaults.baseURL}/namespaces/${namespaceId}/impersonate`
  }

  public async create(data: {
    name: string
    partnerId: string
  }) {
    const result = await api.post<{ namespace: Namespace }>('/namespaces', data)

    return result.data
  }

  public async get(namespaceId: string) {
    const result = await api.get<{ namespace: NamespaceExpanded }>(`/namespaces/${namespaceId}`)

    return result.data.namespace
  }

  public async rename(namespaceId: string, name: string) {
    const result = await api.post<{ namespace: Namespace }>(
      `/namespaces/${namespaceId}/rename`,
      { name }
    )

    return result.data
  }

  public async suspend(namespaceId: string) {
    const result = await api.post<{ namespace: Namespace }>(
      `/namespaces/${namespaceId}/suspend`,
      {}
    )

    return result.data
  }

  public async recover(namespaceId: string) {
    const result = await api.post<{ namespace: Namespace }>(
      `/namespaces/${namespaceId}/recover`,
      {}
    )

    return result.data
  }

  public async listMembers(namespaceId: string, listOptions: {
    pagination?: { take?: number; skip?: number }
    sort?: { by: keyof ClientAccount; desc?: boolean }
    search?: string
    filters?: {
      emailVerified?: boolean
    }
  }) {
    const result = await api.post<{ members: ClientAccount[]; totalCount: number }>(
      `/namespaces/${namespaceId}/members/list`,
      listOptions ?? {}
    )

    return result.data
  }

  public async createMember(namespaceId: string, data: {
    email: string
    password: string
    firstName: string
    lastName: string
  }) {
    const result = await api.post<{ member: ClientAccount }>(`/namespaces/${namespaceId}/members`, data)

    return result.data.member
  }

  public async getMember(namespaceId: string, memberId: string) {
    const result = await api.get<{ member: ClientAccount }>(`/namespaces/${namespaceId}/members/${memberId}`)

    return result.data.member
  }


  public async deleteMember(namespaceId: string, memberId: string) {
    await api.delete(`/namespaces/${namespaceId}/members/${memberId}`)
  }

}

export const namespaceAPI = new NamespaceAPI()
