import { useEffect, useRef } from 'react'
import { config } from '../../config'


interface HeadProps {
  title: string
}

export function Head({ title }: HeadProps) {

  const defaultTitleComponent = useRef(document.title)
  const defaultTitle = config.pageTitle

  useEffect(() => {
    if (title) {
      document.title = defaultTitle ? `${title} | ${defaultTitle}` : title
    }
  }, [ title, defaultTitle ])

  useEffect(() => () => {
    document.title = defaultTitleComponent.current
  }, [])

  return null
}
