import { AxiosError } from 'axios'


interface ErrorMessages {
  default: string
  badRequest?: string
  forbidden?: string
}

export function mutationError(error: unknown, messages: ErrorMessages) {
  let message: string

  if (error instanceof AxiosError) {
    if (error.status === 400 && messages.badRequest) message = messages.badRequest
    if (error.status === 403 && messages.forbidden) message = messages.forbidden
  }

  message ??= messages.default

  return message
}
