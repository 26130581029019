import { AxiosError } from 'axios'
import { api } from './api'
import type { Organization } from './organization'


export type Invitation = {
  id: string
  projectId: string
  organizationId: string
  email: string
  admin: boolean
  expiresAt: string
  createdAt: string
  updatedAt: string
}

export type InvitationExpanded = Invitation & {
  organization: Organization
}

class InvitationAPI {

  public async create(data: {
    email: string
    organization: string
    admin: boolean
  }) {
    const result = await api.post<{ invitation: Invitation }>('/invitations', data)

    return result.data
  }

  public async get(invitationId: string) {
    const result = await api.get<{ invitation: InvitationExpanded }>(`/invitations/${invitationId}`)

    return result.data.invitation
  }

  public async getSafe(invitationId: string) {
    try {
      const result = await api.get<{ invitation: InvitationExpanded }>(`/invitations/${invitationId}`)

      return result.data.invitation
    } catch (error) {
      if (error instanceof AxiosError && error.status === 404) return null

      throw error
    }
  }

  public async delete(invitationId: string) {
    await api.delete(`/invitations/${invitationId}`)
  }

  public async accept(invitationId: string) {
    await api.post(`/invitations/${invitationId}/accept`)
  }

}

export const invitationAPI = new InvitationAPI()
