import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { organizationAPI } from '~/api/organization'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '~/components/ui/alert-dialog'
import { mutationError } from '~/lib/mutation-error'


export default function LeaveOrganizationModal() {
  const { t } = useTranslation([ 'settings', 'general' ])
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const params = useParams() as { organization: string }

  // Close the modal by navigating away
  const close = () => navigate('..')

  // Fetch the organization
  const { data: { name } } = useSuspenseQuery({
    queryKey: [ 'account', 'organizations', params.organization ],
    queryFn: () => organizationAPI.get(params.organization)
  })

  // Leave the organization
  const mutation = useMutation({
    mutationKey: [ 'account', 'organizations', params.organization, 'leave' ],
    mutationFn: () => organizationAPI.leave(params.organization),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'organizations', params.organization ] })
      queryClient.invalidateQueries({ queryKey: [ 'account', 'memberships' ] })
      queryClient.invalidateQueries({ queryKey: [ 'account', 'organizations' ] })
      toast.success(t('leave_organization.success', { name }))
    },
    onError: error => {
      toast.error(mutationError(error, {
        default: t('leave_organization.errors.default')
      }))
    },
    onSettled: () => close()
  })

  return (
    <AlertDialog open>
      <AlertDialogContent onEscapeKeyDown={close}>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('leave_organization.title', { name })}</AlertDialogTitle>
          <AlertDialogDescription>{t('leave_organization.description')}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={close}>
            {t('general:cancel')}
          </AlertDialogCancel>
          <AlertDialogAction onClick={() => mutation.mutate()} disabled={mutation.isPending}>
            {t('leave_organization.submit')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
