import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { accountAPI } from '~/api/account'
import { AlertBox } from '~/components/ui/alert-box'
import { Button } from '~/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip'
import { Subheading, Text } from '~/components/ui/typography'
import { mutationError } from '~/lib/mutation-error'


interface FormInputs {
  password: string
}

export default function DeleteAccountSection() {
  const { t } = useTranslation([ 'settings', 'general' ])
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  // Hide or show password
  const [ hidePassword, setHidePassword ] = useState(true)

  // Fetch account
  const { data: account } = useSuspenseQuery({
    queryKey: [ 'account' ],
    queryFn: () => accountAPI.getSelf()
  })

  // Form state
  const form = useForm<FormInputs>({
    defaultValues: {
      password: ''
    }
  })

  // Delete account
  const mutation = useMutation({
    mutationKey: [ 'account', 'settings', 'delete-account' ],
    mutationFn: (inputs: FormInputs) => accountAPI.delete(
      account.id,
      inputs.password
    ),
    onSuccess: () => {
      form.reset()
      queryClient.clear()
      navigate('/login')
    }
  })

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
      <div>
        <Subheading>{t('delete_account.title')}</Subheading>
        <Text>{t('delete_account.description')}</Text>
      </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(data => mutation.mutate(data))}
          className="space-y-6 sm:maw-w-xl"
        >

          {mutation.error && (
            <AlertBox
              error
              title={mutationError(mutation.error, {
                default: t('delete_account.errors.default'),
                badRequest: t('delete_account.errors.password_incorrect')
              })}
            />
          )}

          <FormField
            name="password"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('delete_account.current_password')}</FormLabel>
                <div className="flex flex-row gap-2">
                  <FormControl>
                    <Input
                      type={hidePassword ? 'password' : 'text'}
                      required
                      autoComplete="new-password"
                      {...field}
                    />
                  </FormControl>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="outline"
                          size="icon"
                          type="button"
                          className="h-10 w-11"
                          onClick={() => setHidePassword(prev => !prev)}
                        >
                          {hidePassword
                            ? <EyeSlashIcon className="text-tertiary" />
                            : <EyeIcon className="text-tertiary" />}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{hidePassword ? t('general:show_pass') : t('general:hide_pass')}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button type="submit" variant="destructive" disabled={mutation.isPending}>
            {t('delete_account.submit')}
          </Button>

        </form>
      </Form>

    </div>
  )
}
