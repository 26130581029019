import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'


export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function initials(value: string): string {
  return value.split(' ').slice(0, 2).map(x => x.at(0)).join('').toUpperCase()
}
