import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { membershipAPI } from '~/api/membership'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '~/components/ui/alert-dialog'
import { mutationError } from '~/lib/mutation-error'


export default function RemoveMemberModal() {
  const { t } = useTranslation([ 'team', 'general' ])
  const navigate = useNavigate()
  const params = useParams() as { organization: string; membership: string }
  const queryClient = useQueryClient()

  // Close the modal by navigating away
  const close = () => navigate('..')

  // Fetch the membership
  const { data: membership } = useSuspenseQuery({
    queryKey: [ 'organizations', params.organization, 'memberships', params.membership ],
    queryFn: () => membershipAPI.get(params.membership)
  })

  // Member name
  const name = `${membership.account.firstName} ${membership.account.lastName}`

  // Delete the membership
  const mutation = useMutation({
    mutationKey: [ 'organizations', params.organization, 'memberships', params.membership, 'remove' ],
    mutationFn: () => membershipAPI.delete(params.membership),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ 'organizations', params.organization, 'memberships' ]
      })
      toast.success(t('remove_member.success', { name }))
    },
    onError: error => {
      toast.error(mutationError(error, {
        default: t('remove_member.errors.default'),
        forbidden: t('remove_member.errors.forbidden')
      }))
    },
    onSettled: () => close()
  })

  return (
    <AlertDialog open>
      <AlertDialogContent onEscapeKeyDown={close}>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('remove_member.title', { name })}</AlertDialogTitle>
          <AlertDialogDescription>{t('remove_member.description')}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={close}>
            {t('general:cancel')}
          </AlertDialogCancel>
          <AlertDialogAction onClick={() => mutation.mutate()} disabled={mutation.isPending}>
            {t('remove_member.submit')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
