import { useTranslation } from 'react-i18next'
import { Head } from '~/components/head'
import LanguageSection from './sections/language-section'
import ThemeSection from './sections/theme-section'


export default function AppearancePage() {
  const { t } = useTranslation([ 'settings' ])

  return (
    <div className="grow divide-y divide-tertiary/5">

      <Head title={t('appearance.page_title')} />

      <ThemeSection />
      <LanguageSection />

    </div>
  )
}
