import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { Head } from '~/components/head'
import { Button } from '~/components/ui/button'
import { Separator } from '~/components/ui/separator'
import { Heading, Text } from '~/components/ui/typography'


export default function PartnerLoading() {
  const { t } = useTranslation([ 'partners' ])
  const params = useParams() as { partner: string }
  const { state } = useLocation()

  // Display name might be available from nav state
  const partnerName = state?.name ?? params.partner

  return (
    <div className="flex grow flex-col gap-4 items-start">

      <Head title={partnerName} />

      <div className="flex w-full items-end justify-between gap-4">
        <Heading>{partnerName}</Heading>
        <Button className="invisible" />
      </div>

      <Separator />

      <Text>{t('view_partner.loading')}</Text>

    </div>
  )
}
