import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import { accountAPI } from '~/api/account'
import { Head } from '~/components/head'
import { Card, CardDescription, CardHeader, CardTitle } from '~/components/ui/card'
import { useAuth } from '~/hooks/use-auth'
import { mutationError } from '~/lib/mutation-error'


export default function VerifyEmailPage() {
  const { t } = useTranslation([ 'general' ])
  const queryClient = useQueryClient()
  const [ searchParams ] = useSearchParams()
  const navigate = useNavigate()
  const { account } = useAuth()

  // Get the email verification token
  const token = searchParams.get('token') ?? ''

  // Verify email address
  const mutation = useMutation({
    mutationKey: [ 'account', 'settings', 'verify-email' ],
    mutationFn: () => accountAPI.verifyEmail(token),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'account' ] })
      toast.success(t('verify_email.success'))
    },
    onError: error => {
      toast.error(mutationError(error, {
        default: t('verify_email.errors.default'),
        badRequest: t('verify_email.errors.expired')
      }))
    },
    onSettled: () => navigate(account ? '/' : '/login', { replace: true })
  })

  // Run verification
  useEffect(() => {
    if (mutation.isIdle) mutation.mutate()
  }, [ mutation ])

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">

      <Head title={t('verify_email.title')} />

      <Card className="sm:mx-auto sm:w-full sm:max-w-sm">
        <CardHeader>
          <CardTitle>{t('verify_email.title')}</CardTitle>
          <CardDescription>{t('verify_email.verifying')}</CardDescription>
        </CardHeader>
      </Card>

    </div>
  )
}
