import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { accountAPI } from '~/api/account'
import { Head } from '~/components/head'
import { AlertBox } from '~/components/ui/alert-box'
import { Button } from '~/components/ui/button'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { mutationError } from '~/lib/mutation-error'


interface FormInputs {
  email: string
}

export default function ForgotPasswordPage() {
  const { t } = useTranslation([ 'auth', 'general' ])

  // Form state
  const form = useForm<FormInputs>({
    defaultValues: {
      email: ''
    }
  })

  // Request password reset
  const mutation = useMutation({
    mutationKey: [ 'auth', 'forgot-password' ],
    mutationFn: (inputs: FormInputs) => accountAPI.forgotPassword(inputs.email),
    onSuccess: () => {
      form.reset()
    }
  })

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">

      <Head title={t('forgot_password.page_title')} />

      <div className="sm:mx-auto sm:w-full sm:max-w-sm -mt-8">
        <Link to="../login">
          <img
            src="https://cleverev.net/assets/logo.svg"
            alt={t('general:logo_alt')}
            className="mx-auto h-12 w-auto"
          />
        </Link>
        <h1 className="mt-10 mb-10 text text-xl text-center font-bold">
          {t('forgot_password.heading')}
        </h1>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(data => mutation.mutate(data))} className="space-y-6">

          {mutation.error && (
            <AlertBox
              error
              title={mutationError(mutation.error, {
                default: t('forgot_password.errors.default')
              })}
            />
          )}

          {mutation.isSuccess && (
            <AlertBox success title={t('forgot_password.success')} />
          )}

          <FormField
            name="email"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('forgot_password.email')}</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    required
                    autoFocus
                    autoComplete="email"
                    {...field}
                  />
                </FormControl>
                <FormDescription>{t('forgot_password.email_description')}</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            className="w-full"
            disabled={mutation.isPending}
          >
            {t('forgot_password.submit')}
          </Button>

          <p className="text-center text-sm text-tertiary">
            <Trans
              i18nKey="auth:forgot_password.to_login"
              components={[ <Link key="login" to="../login" className="font-medium text-primary" /> ]}
            />
          </p>

        </form>
      </Form>

    </div>
  )
}
