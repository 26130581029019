import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { LanguageSelector } from '~/components/language-selector'
import { ThemeSelector } from '~/components/theme-selector'
import { Avatar, AvatarImage } from '~/components/ui/avatar'
import { Button } from '~/components/ui/button'


export default function UnauthenticatedLayout() {
  const { t } = useTranslation([ 'general' ])

  return (
    <div className="relative isolate flex min-h-svh w-full flex-col">

      <header className="flex items-center px-4 py-2">
        <div className="min-w-0 flex-1 flex items-center gap-4">

          <Button variant="ghost">
            <Avatar className="h-6 w-6">
              <AvatarImage src="https://cleverev.net/assets/logo.svg" />
            </Avatar>
            <span>{t('product_name')}</span>
          </Button>

          <div className="flex-1" />

          <div className="flex flex-row gap-4">
            <LanguageSelector />
            <ThemeSelector />
          </div>

        </div>
      </header>

      <main className="flex flex-1 flex-col pb-2 lg:px-2">
        <div className="flex-1 flex flex-col min-h-full justify-center px-6 py-12 lg:px-8">
          <Outlet />
        </div>
      </main>

    </div>
  )
}
