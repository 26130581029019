import { useTranslation } from 'react-i18next'
import { useTheme, type Theme } from '~/components/theme-provider'
import { Label } from '~/components/ui/label'
import { RadioGroup, RadioGroupItem } from '~/components/ui/radio-group'
import { Subheading, Text } from '~/components/ui/typography'


export default function ThemeSection() {
  const { t } = useTranslation([ 'settings' ])
  const { theme, setTheme } = useTheme()

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
      <div>
        <Subheading>{t('theme.title')}</Subheading>
        <Text>{t('theme.description')}</Text>
      </div>

      <RadioGroup
        value={theme}
        onValueChange={x => setTheme(x as Theme)}
        className="flex flex-col space-y-2"
      >
        <div className="flex items-center space-x-4">
          <RadioGroupItem value="light" id="light" />
          <Label htmlFor="light" className="font-normal">{t('theme.light')}</Label>
        </div>
        <div className="flex items-center space-x-4">
          <RadioGroupItem value="dark" id="dark" />
          <Label htmlFor="dark" className="font-normal">{t('theme.dark')}</Label>
        </div>
        <div className="flex items-center space-x-4">
          <RadioGroupItem value="system" id="system" />
          <Label htmlFor="system" className="font-normal">{t('theme.system')}</Label>
        </div>
      </RadioGroup>

    </div>
  )
}
