import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { invitationAPI } from '~/api/invitation'
import { Head } from '~/components/head'
import { Button } from '~/components/ui/button'
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '~/components/ui/card'
import { Subheading, Text } from '~/components/ui/typography'
import { useAuth } from '~/hooks/use-auth'
import { mutationError } from '~/lib/mutation-error'


export default function InvitationPage() {
  const { t } = useTranslation([ 'general' ])
  const params = useParams() as { invitation: string }
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { account } = useAuth()

  // Fetch the invitation
  const { data: invitation } = useSuspenseQuery({
    queryKey: [ 'invitations', params.invitation ],
    queryFn: () => invitationAPI.getSafe(params.invitation)
  })

  // Accept invitation
  const acceptMutation = useMutation({
    mutationKey: [ 'invitations', params.invitation, 'accept' ],
    mutationFn: () => invitationAPI.accept(params.invitation),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'account', 'organizations' ] })
      queryClient.invalidateQueries({ queryKey: [ 'account', 'memberships' ] })
      toast.success(t('invitation.view.accept.success'))
      navigate(`/${invitation!.organization.shortId}`, { replace: true })
    },
    onError: error => {
      toast.error(mutationError(error, {
        default: t('invitation.view.accept.errors.default')
      }))
    }
  })

  // Reject invitation
  const rejectMutation = useMutation({
    mutationKey: [ 'invitations', params.invitation, 'reject' ],
    mutationFn: () => invitationAPI.delete(params.invitation),
    onSuccess: () => {
      toast.success(t('invitation.view.reject.success'))
      navigate('../rejected', { replace: true })
    },
    onError: error => {
      toast.error(mutationError(error, {
        default: t('invitation.view.reject.errors.default')
      }))
    }
  })

  // Redirect if the invitation is not found
  if (!invitation) {
    return (
      <Navigate to="../invitations/invalid" replace />
    )
  }

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">

      <Head title={t('invitation.title')} />

      <Card className="sm:mx-auto sm:w-full sm:max-w-sm">
        <CardHeader>
          <CardTitle>{t('invitation.view.title', { name: invitation.organization.name })}</CardTitle>
          <CardDescription>{t('invitation.view.description')}</CardDescription>
        </CardHeader>
        <CardContent>
          <Subheading>{t('invitation.view.invitee')}</Subheading>
          <Text>{invitation.email}</Text>
        </CardContent>
        {account?.email === invitation.email ? (
          <CardFooter className="flex flex-col gap-4">
            <Button
              className="w-full"
              disabled={acceptMutation.isPending || rejectMutation.isPending}
              onClick={() => acceptMutation.mutate()}
            >
              {t('invitation.view.accept.button')}
            </Button>
            <Button
              variant="secondary"
              className="w-full"
              disabled={acceptMutation.isPending || rejectMutation.isPending}
              onClick={() => rejectMutation.mutate()}
            >
              {t('invitation.view.reject.button')}
            </Button>
          </CardFooter>
        ) : (
          <CardFooter className="flex flex-col gap-4">
            <Link to={`/login?redirect=${pathname}`} className="w-full">
              <Button className="w-full">
                {t('invitation.view.log_in')}
              </Button>
            </Link>
            <Link to={`/create-account?invitation=${params.invitation}`} className="w-full">
              <Button className="w-full">
                {t('invitation.view.create_account')}
              </Button>
            </Link>
          </CardFooter>
        )}
      </Card>

    </div>
  )
}
