import { useRouteError } from 'react-router-dom'
import { ErrorBoundary } from '~/components/error-boundary'


export default function UnauthenticatedError() {
  const error = useRouteError()

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-lg">
      <ErrorBoundary error={error} />
    </div>
  )
}
