import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { Head } from '~/components/head'
import { Button } from '~/components/ui/button'
import { Separator } from '~/components/ui/separator'
import { Heading, Text } from '~/components/ui/typography'


export default function CustomerLoading() {
  const { t } = useTranslation([ 'customers' ])
  const params = useParams() as { customer: string }
  const { state } = useLocation()

  // Display name might be available from nav state
  const customerName = state?.name ?? params.customer

  return (
    <div className="flex grow flex-col gap-4 items-start">

      <Head title={customerName} />

      <div className="flex w-full items-end justify-between gap-4">
        <Heading>{customerName}</Heading>
        <Button className="invisible" />
      </div>

      <Separator />

      <Text>{t('view_customer.loading')}</Text>

    </div>
  )
}
