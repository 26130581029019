import { captureException } from '@sentry/react'
import { AxiosError } from 'axios'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useRouteError } from 'react-router-dom'
import { AlertBox } from '~/components/ui/alert-box'


export default function GlobalError() {
  const { t } = useTranslation([ 'general' ])
  const error = useRouteError()
  const { pathname } = useLocation()

  // Log error to Sentry
  const errorId = useMemo(() => captureException(error), [ error ])

  // If the user is unauthenticated, redirect them to the login page
  if (error instanceof AxiosError && error.status === 401) {
    return (
      <Navigate to={`/login?redirect=${pathname}`} replace />
    )
  }

  // If not found or not permitted, redirect to the home page
  if (error instanceof AxiosError && (error.status === 404 || error.status === 403)) {
    return (
      <Navigate to="/" replace />
    )
  }

  // Show error otherwise
  return (
    <div className="w-full p-8">
      <AlertBox
        error
        title={t('unexpected_error')}
        description={t('unexpected_error_hint', { errorId })}
        buttons={[
          { title: t('error_fix_reload'), onClick: () => location.reload() },
          { title: t('error_fix_back'), onClick: () => history.back() }
        ]}
      />
    </div>
  )
}
