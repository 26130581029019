import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '~/components/ui/button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '~/components/ui/dialog'
import { organizationAPI } from '~/api/organization'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { AlertBox } from '~/components/ui/alert-box'
import { mutationError } from '~/lib/mutation-error'


interface FormInputs {
  name: string
}

export default function NewPartnerModal() {
  const { t } = useTranslation([ 'partners', 'general' ])
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const params = useParams() as { organization: string }

  // Close the modal by navigating away
  const close = () => navigate('..')

  // Form state
  const form = useForm<FormInputs>({
    defaultValues: {
      name: ''
    }
  })

  // Create the partner organization
  const mutation = useMutation({
    mutationKey: [ 'organizations', params.organization, 'partners', 'create' ],
    mutationFn: (inputs: FormInputs) => organizationAPI.create({
      name: inputs.name,
      parentId: params.organization
    }),
    onSuccess: ({ organization: partner }) => {
      queryClient.invalidateQueries({ queryKey: [ 'organizations', params.organization, 'partners' ] })
      queryClient.setQueryData(
        [ 'organizations', params.organization, 'partners', partner.shortId ],
        partner
      )
      navigate(`../${partner.shortId}`, { replace: true })
    }
  })

  return (
    <Dialog open modal onOpenChange={close}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('new_partner.title')}</DialogTitle>
          <DialogDescription>{t('new_partner.description')}</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(inputs => mutation.mutate(inputs))}
            className="flex flex-col gap-4"
          >

            {mutation.error && (
              <AlertBox
                error
                title={mutationError(mutation.error, {
                  default: t('new_partner.errors.default'),
                  forbidden: t('new_partner.errors.forbidden')
                })}
              />
            )}

            <FormField
              name="name"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('fields.name')}</FormLabel>
                  <FormControl>
                    <Input
                      required
                      maxLength={128}
                      autoComplete="off"
                      autoFocus
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter className="gap-2 justify-start">
              <Button type="button" variant="outline" onClick={close}>
                {t('general:cancel')}
              </Button>
              <Button type="submit" disabled={mutation.isPending}>
                {t('new_partner.submit')}
              </Button>
            </DialogFooter>

          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
