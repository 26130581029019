import { useTranslation } from 'react-i18next'
import { Label } from '~/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '~/components/ui/select'
import { Subheading, Text } from '~/components/ui/typography'


export default function LanguageSection() {
  const { t } = useTranslation([ 'settings' ])

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
      <div>
        <Subheading>{t('language.title')}</Subheading>
        <Text>{t('language.description')}</Text>
      </div>

      <div className="flex flex-col gap-4">
        <Label>{t('language.language')}</Label>
        <Select value="en">
          <SelectTrigger>
            <SelectValue placeholder={t('language.en')} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="en">{t('language.en')}</SelectItem>
          </SelectContent>
        </Select>
      </div>

    </div>
  )
}
