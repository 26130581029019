import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { accountAPI } from '~/api/account'
import { Head } from '~/components/head'
import { AlertBox } from '~/components/ui/alert-box'
import { Button } from '~/components/ui/button'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip'
import { mutationError } from '~/lib/mutation-error'


interface FormInputs {
  newPassword: string
}

export default function ResetPasswordPage() {
  const { t } = useTranslation([ 'auth', 'general' ])
  const [ searchParams ] = useSearchParams()

  // Hide or show password
  const [ hidePassword, setHidePassword ] = useState(true)

  // Form state
  const form = useForm<FormInputs>({
    defaultValues: {
      newPassword: ''
    }
  })

  // Reset the password
  const mutation = useMutation({
    mutationKey: [ 'auth', 'reset-password' ],
    mutationFn: (inputs: FormInputs) => accountAPI.resetPassword(
      searchParams.get('token') ?? '',
      inputs.newPassword
    ),
    onSuccess: () => {
      form.reset()
    }
  })

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">

      <Head title={t('reset_password.page_title')} />

      <div className="sm:mx-auto sm:w-full sm:max-w-sm -mt-8">
        <Link to="../login">
          <img
            src="https://cleverev.net/assets/logo.svg"
            alt={t('general:logo_alt')}
            className="mx-auto h-12 w-auto"
          />
        </Link>
        <h1 className="mt-10 mb-10 text text-xl text-center font-bold">
          {t('reset_password.heading')}
        </h1>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(data => mutation.mutate(data))} className="space-y-6">

          {mutation.error && (
            <AlertBox
              error
              title={mutationError(mutation.error, {
                default: t('reset_password.errors.default'),
                badRequest: t('reset_password.errors.token_expired')
              })}
            />
          )}

          {mutation.isSuccess && (
            <AlertBox success title={t('reset_password.success')} />
          )}

          <FormField
            name="newPassword"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('reset_password.new_password')}</FormLabel>
                <div className="flex flex-row gap-2">
                  <FormControl>
                    <Input
                      type={hidePassword ? 'password' : 'text'}
                      required
                      autoComplete="new-password"
                      {...field}
                    />
                  </FormControl>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="outline"
                          size="icon"
                          type="button"
                          className="h-10 w-11"
                          onClick={() => setHidePassword(prev => !prev)}
                        >
                          {hidePassword
                            ? <EyeSlashIcon className="text-tertiary" />
                            : <EyeIcon className="text-tertiary" />}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{hidePassword ? t('general:show_pass') : t('general:hide_pass')}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <FormDescription>{t('reset_password.new_password_description')}</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            className="w-full"
            disabled={mutation.isPending}
          >
            {t('reset_password.submit')}
          </Button>

          <p className="text-center text-sm text-tertiary">
            <Trans
              i18nKey="auth:reset_password.to_login"
              components={[ <Link key="login" to="../login" className="font-medium text-primary" /> ]}
            />
          </p>

        </form>
      </Form>

    </div>
  )
}
