import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { EmptyState } from '~/components/empty-state'
import { Head } from '~/components/head'
import { Button } from '~/components/ui/button'
import { Separator } from '~/components/ui/separator'
import { Heading } from '~/components/ui/typography'


export default function NoOrganizationHomePage() {
  const { t } = useTranslation([ 'general' ])

  return (
    <div className="flex grow flex-col gap-4 items-start">

      <Head title={t('no_organization_home.title')} />

      <div className="flex w-full items-end justify-between gap-4">
        <Heading>{t('no_organization_home.title')}</Heading>
        <Button className="invisible" />
      </div>

      <Separator />

      <EmptyState
        title={t('no_organization_home.empty_title')}
        description={t('no_organization_home.empty_description')}
        Icon={QuestionMarkCircleIcon}
      />

    </div>
  )
}
