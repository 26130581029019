import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { clsx } from 'clsx'


interface BaseAlertBoxProps {
  title: string
  description?: string
  buttons?: {
    title: string
    onClick?: () => unknown
    disabled?: boolean
  }[]
  className?: string
}

function ErrorBox({ title, description, buttons, className }: BaseAlertBoxProps) {
  return (
    <div className={clsx('w-full rounded-md bg-red-50 dark:bg-red-950 dark:bg-opacity-30 p-4', !description && !buttons && 'py-3', className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon aria-hidden="true" className={'h-5 w-5 text-red-400'} />
        </div>
        <div className="ml-3 flex flex-col gap-4">
          <h3 className={'text-sm font-medium text-red-800 dark:text-red-500'}>
            {title}
          </h3>
          {description && (
            <div className={'text-sm text-red-700 dark:text-red-200'}>
              <p>{description}</p>
            </div>
          )}
          {buttons && (
            <div className="flex gap-4">
              {buttons.map((button, index) => (
                <button
                  key={index}
                  type="button"
                  className={clsx(index > 0 && 'ml-3', 'rounded-md bg-red-50 dark:bg-red-950 dark:bg-opacity-30 px-2 py-1.5 text-sm font-medium text-red-800 dark:text-red-200 hover:bg-red-100 dark:hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50')}
                  onClick={button.onClick}
                  disabled={button.disabled}
                >
                  {button.title}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function WarningBox({ title, description, buttons, className }: BaseAlertBoxProps) {
  return (
    <div className={clsx('w-full rounded-md bg-yellow-50 dark:bg-yellow-950 dark:bg-opacity-30 p-4', !description && !buttons && 'py-3', className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon aria-hidden="true" className={'h-5 w-5 text-yellow-400'} />
        </div>
        <div className="ml-3 flex flex-col gap-4">
          <h3 className={'text-sm font-medium text-yellow-800 dark:text-yellow-500'}>
            {title}
          </h3>
          {description && (
            <div className={'text-sm text-yellow-700 dark:text-yellow-200'}>
              <p>{description}</p>
            </div>
          )}
          {buttons && (
            <div className="flex gap-4">
              {buttons.map((button, index) => (
                <button
                  key={index}
                  type="button"
                  className={clsx(index > 0 && 'ml-3', 'rounded-md bg-yellow-50 dark:bg-yellow-950 dark:bg-opacity-30 px-2 py-1.5 text-sm font-medium text-yellow-800 dark:text-yellow-200 hover:bg-yellow-100 dark:hover:bg-yellow-900 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50')}
                  onClick={button.onClick}
                  disabled={button.disabled}
                >
                  {button.title}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function SuccessBox({ title, description, buttons, className }: BaseAlertBoxProps) {
  return (
    <div className={clsx('w-full rounded-md bg-green-50 dark:bg-green-950 dark:bg-opacity-30 p-4', !description && !buttons && 'py-3', className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon aria-hidden="true" className={'h-5 w-5 text-green-400'} />
        </div>
        <div className="ml-3 flex flex-col gap-4">
          <h3 className={'text-sm font-medium text-green-800 dark:text-green-500'}>
            {title}
          </h3>
          {description && (
            <div className={'text-sm text-green-700 dark:text-green-200'}>
              <p>{description}</p>
            </div>
          )}
          {buttons && (
            <div className="flex gap-4">
              {buttons.map((button, index) => (
                <button
                  key={index}
                  type="button"
                  className={clsx(index > 0 && 'ml-3', 'rounded-md bg-green-50 dark:bg-green-950 dark:bg-opacity-30 px-2 py-1.5 text-sm font-medium text-green-800 dark:text-green-200 hover:bg-green-100 dark:hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50')}
                  onClick={button.onClick}
                  disabled={button.disabled}
                >
                  {button.title}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function InfoBox({ title, description, buttons, className }: BaseAlertBoxProps) {
  return (
    <div className={clsx('w-full rounded-md bg-blue-50 dark:bg-blue-950 dark:bg-opacity-30 p-4', !description && !buttons && 'py-3', className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon aria-hidden="true" className={'h-5 w-5 text-blue-400'} />
        </div>
        <div className="ml-3 flex flex-col gap-4">
          <h3 className={'text-sm font-medium text-blue-800 dark:text-blue-500'}>
            {title}
          </h3>
          {description && (
            <div className={'text-sm text-blue-700 dark:text-blue-200'}>
              <p>{description}</p>
            </div>
          )}
          {buttons && (
            <div className="flex gap-4">
              {buttons.map((button, index) => (
                <button
                  key={index}
                  type="button"
                  className={clsx(index > 0 && 'ml-3', 'rounded-md bg-blue-50 dark:bg-blue-950 dark:bg-opacity-30 px-2 py-1.5 text-sm font-medium text-blue-800 dark:text-blue-200 hover:bg-blue-100 dark:hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50')}
                  onClick={button.onClick}
                  disabled={button.disabled}
                >
                  {button.title}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

interface AlertBoxProps extends BaseAlertBoxProps {
  error?: true
  success?: true
  warning?: true
}

export function AlertBox({ error, warning, success, ...props }: AlertBoxProps) {
  if (error) return <ErrorBox {...props} />
  if (warning) return <WarningBox {...props} />
  if (success) return <SuccessBox {...props} />

  return <InfoBox {...props} />
}
