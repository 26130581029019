import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '~/components/ui/button'
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '~/components/ui/dialog'
import { membershipAPI } from '~/api/membership'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '~/components/ui/form'
import { AlertBox } from '~/components/ui/alert-box'
import { mutationError } from '~/lib/mutation-error'
import { toast } from 'sonner'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '~/components/ui/select'


interface FormInputs {
  level: 'admin' | 'member'
}

export default function ChangeMemberAccessModal() {
  const { t } = useTranslation([ 'team', 'general' ])
  const navigate = useNavigate()
  const params = useParams() as { organization: string; membership: string }
  const queryClient = useQueryClient()

  // Close the modal by navigating away
  const close = () => navigate('..')

  // Fetch the membership
  const { data: membership } = useSuspenseQuery({
    queryKey: [ 'organizations', params.organization, 'memberships', params.membership ],
    queryFn: () => membershipAPI.get(params.membership)
  })

  // Member name
  const name = `${membership.account.firstName} ${membership.account.lastName}`

  // Form state
  const form = useForm<FormInputs>({
    defaultValues: {
      level: membership.admin ? 'admin' : 'member'
    }
  })

  // Change the access level of the membership
  const mutation = useMutation({
    mutationKey: [ 'organizations', params.organization, 'memberships', params.membership, 'change-access' ],
    mutationFn: (inputs: FormInputs) => membershipAPI.changeAccessLevel(
      params.membership,
      inputs.level === 'admin'
    ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ 'organizations', params.organization, 'memberships' ]
      })
      toast.success(t('change_member_access.success', { name }))
      close()
    }
  })

  return (
    <Dialog open modal onOpenChange={close}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('change_member_access.title', { name })}</DialogTitle>
          <DialogDescription>{t('change_member_access.description')}</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(inputs => mutation.mutate(inputs))}
            className="flex flex-col gap-4"
          >

            {mutation.error && (
              <AlertBox
                error
                title={mutationError(mutation.error, {
                  default: t('change_member_access.errors.default'),
                  forbidden: t('change_member_access.errors.forbidden')
                })}
              />
            )}

            <FormField
              name="level"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('fields.level')}</FormLabel>
                  <FormControl>
                    <Select defaultValue={field.value} onValueChange={field.onChange}>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="admin">
                          {t('fields.level_enum.admin')}
                        </SelectItem>
                        <SelectItem value="member">
                          {t('fields.level_enum.member')}
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter className="gap-2 justify-start">
              <Button type="button" variant="outline" onClick={close}>
                {t('general:cancel')}
              </Button>
              <Button type="submit" disabled={mutation.isPending}>
                {t('change_member_access.submit')}
              </Button>
            </DialogFooter>

          </form>
        </Form>

      </DialogContent>
    </Dialog>
  )
}
