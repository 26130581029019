import { useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { accountAPI } from '~/api/account'
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '~/components/ui/command'


interface OrganizationSelectorProps {
  open: boolean
  onOpenChange: (open: boolean) => void
}

export function OrganizationSelector(props: OrganizationSelectorProps) {
  const { t } = useTranslation([ 'general' ])
  const navigate = useNavigate()

  // Fetch account
  const { data: account } = useSuspenseQuery({
    queryKey: [ 'account' ],
    queryFn: () => accountAPI.getSelf()
  })

  // Fetch accessible organizations
  const { data: { organizations } } = useSuspenseQuery({
    queryKey: [ 'account', 'organizations' ],
    queryFn: () => accountAPI.listOrganizations(account.id)
  })

  // Navigate to organization and close dialog
  function select(shortId: string) {
    props.onOpenChange(false)
    navigate(shortId)
  }

  return (
    <CommandDialog
      open={props.open}
      onOpenChange={props.onOpenChange}
      title={t('org_selector.title')}
      description={t('org_selector.description')}
    >
      <CommandInput placeholder={t('org_selector.placeholder')} />
      <CommandList>
        <CommandEmpty>{t('org_selector.empty')}</CommandEmpty>
        <CommandGroup>
          {organizations.map(organization => (
            <CommandItem
              key={`org_selector_${organization.id}`}
              value={organization.shortId}
              onSelect={select}
              keywords={[ organization.name, organization.shortId, organization.id ]}
            >
              {organization.name}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  )
}
