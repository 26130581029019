import { ClipboardDocumentIcon, EllipsisVerticalIcon, PlusIcon, TrashIcon } from '@heroicons/react/20/solid'
import { FolderPlusIcon } from '@heroicons/react/24/outline'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { type Invitation } from '~/api/invitation'
import { organizationAPI } from '~/api/organization'
import { EmptyState } from '~/components/empty-state'
import { Button } from '~/components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '~/components/ui/table'
import { Subheading } from '~/components/ui/typography'
import { Avatar, AvatarFallback } from '~/components/ui/avatar'
import { initials } from '~/lib/utils'
import { Badge } from '~/components/ui/badge'
import { formatDateTimeRange } from '~/lib/datetime'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'
import { copy } from '~/lib/copy'


export default function InvitationsPage() {
  const { t, i18n } = useTranslation([ 'team' ])
  const params = useParams() as { organization: string }
  const queryClient = useQueryClient()

  // Fetch the invitations
  const { data: { invitations, totalCount } } = useSuspenseQuery({
    queryKey: [ 'organizations', params.organization, 'invitations' ],
    queryFn: () => organizationAPI.listInvitations(params.organization, {}),
    select: data => {
      data.invitations.forEach(invitation => {
        queryClient.setQueryData<Invitation>(
          [ 'organizations', params.organization, 'invitations', invitation.id ],
          invitation
        )
      })

      return data
    }
  })

  // Copy invitee email and notify
  function copyInviteeEmail(invitation: Invitation) {
    copy(invitation.email)
    toast.info(t('invitation_list.email_copied'))
  }

  return (
    <div className="w-full flex grow flex-col gap-4 items-start">

      <Outlet />

      {totalCount === 0 ? (
        <EmptyState
          title={t('invitation_list.empty_title')}
          description={t('invitation_list.empty_description')}
          Icon={FolderPlusIcon}
        >
          <Link to="./new">
            <Button>
              <PlusIcon />
              {t('action_invite_member')}
            </Button>
          </Link>
        </EmptyState>
      ) : (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t('invitation_list.table.invitee')}</TableHead>
                <TableHead>{t('invitation_list.table.status')}</TableHead>
                <TableHead>{t('invitation_list.table.access')}</TableHead>
                <TableHead>{t('invitation_list.table.expires')}</TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody>
              {invitations.map(invitation => (
                <TableRow key={invitation.id}>
                  <TableCell className="font-medium">
                    <div className="flex flex-row items-center gap-4">
                      <Avatar>
                        <AvatarFallback>{initials(invitation.email)}</AvatarFallback>
                      </Avatar>
                      <Subheading>{invitation.email}</Subheading>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Badge color="orange">{t('invitation_list.pending')}</Badge>
                  </TableCell>
                  <TableCell>
                    {invitation.admin
                      ? <Badge color="pink">{t('fields.level_enum.admin').toLowerCase()}</Badge>
                      : <Badge color="sky">{t('fields.level_enum.member').toLowerCase()}</Badge>
                    }
                  </TableCell>
                  <TableCell>
                    {formatDateTimeRange(invitation.expiresAt, i18n.language)}
                  </TableCell>
                  <TableCell className="text-right flex flex-row justify-end">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="icon">
                          <EllipsisVerticalIcon />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuLabel>{t('invitation_list.menu.label')}</DropdownMenuLabel>
                        <Link to={`./${invitation.id}/cancel`}>
                          <DropdownMenuItem>
                            <TrashIcon className="text-tertiary" />
                            {t('invitation_list.menu.cancel')}
                          </DropdownMenuItem>
                        </Link>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={() => copyInviteeEmail(invitation)}>
                          <ClipboardDocumentIcon className="text-tertiary" />
                          {t('invitation_list.menu.copy_email')}
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}

    </div>
  )
}
