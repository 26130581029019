import { CheckIcon, ComputerDesktopIcon, MoonIcon, SunIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'
import { useTheme } from '~/components/theme-provider'
import { Button } from '~/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'


export function ThemeSelector() {
  const { t } = useTranslation([ 'general' ])
  const { theme, setTheme, isDark } = useTheme()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="text-tertiary">
        <Button variant="ghost" size="icon">
          {isDark ? <MoonIcon /> : <SunIcon />}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => setTheme('light')}>
          <SunIcon className="text-tertiary h-4 w-4" />
          <span className="flex-1">{t('themes.light')}</span>
          {theme === 'light' && (
            <CheckIcon className="text-tertiary h-4 w-4" />
          )}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme('dark')}>
          <MoonIcon className="text-tertiary h-4 w-4" />
          <span className="flex-1">{t('themes.dark')}</span>
          {theme === 'dark' && (
            <CheckIcon className="text-tertiary h-4 w-4" />
          )}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme('system')}>
          <ComputerDesktopIcon className="text-tertiary h-4 w-4" />
          <span className="flex-1">{t('themes.system')}</span>
          {theme === 'system' && (
            <CheckIcon className="text-tertiary h-4 w-4" />
          )}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
