import { useLocation, useParams, useRouteError } from 'react-router-dom'
import { ErrorBoundary } from '~/components/error-boundary'
import { Head } from '~/components/head'
import { Button } from '~/components/ui/button'
import { Separator } from '~/components/ui/separator'
import { Heading } from '~/components/ui/typography'


export default function PartnerError() {
  const params = useParams() as { partner: string }
  const { state } = useLocation()
  const error = useRouteError()

  // Display name might be available from nav state
  const partnerName = state?.name ?? params.partner

  return (
    <div className="flex grow flex-col gap-4 items-start">

      <Head title={partnerName} />

      <div className="flex w-full items-end justify-between gap-4">
        <Heading>{partnerName}</Heading>
        <Button className="invisible" />
      </div>

      <Separator />

      <ErrorBoundary error={error} />

    </div>
  )
}
