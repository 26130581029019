import { useRouteError } from 'react-router-dom'
import { ErrorBoundary } from '~/components/error-boundary'


export default function OrganizationError() {
  const error = useRouteError()

  return (
    <div>
      <ErrorBoundary error={error} />
    </div>
  )
}
