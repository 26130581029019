import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '~/components/ui/button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '~/components/ui/dialog'
import { namespaceAPI } from '~/api/namespace'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { AlertBox } from '~/components/ui/alert-box'
import { mutationError } from '~/lib/mutation-error'


interface FormInputs {
  name: string
}

export default function NewCustomerModal() {
  const { t } = useTranslation([ 'customers', 'general' ])
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const params = useParams() as { organization: string }

  // Close the modal by navigating away
  const close = () => navigate('..')

  // Form state
  const form = useForm<FormInputs>({
    defaultValues: {
      name: ''
    }
  })

  // Create the customer organization
  const mutation = useMutation({
    mutationKey: [],
    mutationFn: (inputs: FormInputs) => namespaceAPI.create({
      name: inputs.name,
      partnerId: params.organization
    }),
    onSuccess: ({ namespace: customer }) => {
      queryClient.invalidateQueries({ queryKey: [ 'organizations', params.organization, 'customers' ] })
      queryClient.setQueryData(
        [ 'organizations', params.organization, 'customers', customer.shortId ],
        customer
      )
      navigate(`../${customer.shortId}`, { replace: true })
    }
  })

  return (
    <Dialog open modal onOpenChange={close}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('new_customer.title')}</DialogTitle>
          <DialogDescription>{t('new_customer.description')}</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(inputs => mutation.mutate(inputs))}
            className="flex flex-col gap-4"
          >

            {mutation.error && (
              <AlertBox
                error
                title={mutationError(mutation.error, {
                  default: t('new_customer.errors.default'),
                  forbidden: t('new_customer.errors.forbidden')
                })}
              />
            )}

            <FormField
              name="name"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('fields.name')}</FormLabel>
                  <FormControl>
                    <Input
                      required
                      maxLength={128}
                      autoComplete="off"
                      autoFocus
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter className="gap-2 justify-start">
              <Button type="button" variant="outline" onClick={close}>
                {t('general:cancel')}
              </Button>
              <Button type="submit" disabled={mutation.isPending}>
                {t('new_customer.submit')}
              </Button>
            </DialogFooter>

          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
