import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { namespaceAPI } from '~/api/namespace'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '~/components/ui/alert-dialog'
import { mutationError } from '~/lib/mutation-error'


export default function RemoveCustomerMemberModal() {
  const { t } = useTranslation([ 'customers', 'general' ])
  const params = useParams() as { organization: string; customer: string; member: string }
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const close = () => navigate('..')

  const { data: member } = useSuspenseQuery({
    queryKey: [ 'organizations', params.organization, 'customers', params.customer, 'members', params.member ],
    queryFn: () => namespaceAPI.getMember(params.customer, params.member)
  })

  const mutation = useMutation({
    mutationKey: [ 'organizations', params.organization, 'customers', params.customer, 'members', params.member, 'remove' ],
    mutationFn: () => namespaceAPI.deleteMember(params.customer, params.member),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ 'organizations', params.organization, 'customers', params.customer, 'members' ]
      })
      toast.success(t('remove_member.success', { name: member.properties.fullname }))
    },
    onError: error => {
      toast.error(mutationError(error, {
        default: t('remove_member.errors.default'),
        forbidden: t('remove_member.errors.forbidden')
      }))
    },
    onSettled: () => close()
  })

  return (
    <AlertDialog open>
      <AlertDialogContent onEscapeKeyDown={close}>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('remove_member.title', { name: member.properties.fullname })}</AlertDialogTitle>
          <AlertDialogDescription>{t('remove_member.description')}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={close}>
            {t('general:cancel')}
          </AlertDialogCancel>
          <AlertDialogAction onClick={() => mutation.mutate()} disabled={mutation.isPending}>
            {t('remove_member.submit')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
