import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { accountAPI } from '~/api/account'
import { AlertBox } from '~/components/ui/alert-box'
import { Button } from '~/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip'
import { Subheading, Text } from '~/components/ui/typography'
import { mutationError } from '~/lib/mutation-error'


interface FormInputs {
  currentPassword: string
  newPassword: string
}

export default function ChangePasswordSection() {
  const { t } = useTranslation([ 'settings', 'general' ])

  // Hide or show passwords
  const [ hideCurrentPassword, setHideCurrentPassword ] = useState(true)
  const [ hideNewPassword, setHideNewPassword ] = useState(true)

  // Fetch account
  const { data: account } = useSuspenseQuery({
    queryKey: [ 'account' ],
    queryFn: () => accountAPI.getSelf()
  })

  // Form state
  const form = useForm<FormInputs>({
    defaultValues: {
      currentPassword: '',
      newPassword: ''
    }
  })

  // Change password
  const mutation = useMutation({
    mutationKey: [ 'account', 'settings', 'change-password' ],
    mutationFn: (inputs: FormInputs) => accountAPI.changePassword(
      account.id,
      inputs.currentPassword,
      inputs.newPassword
    ),
    onSuccess: () => {
      form.reset()
      toast.success(t('change_password.success'))
    }
  })

  return (
    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
      <div>
        <Subheading>{t('change_password.title')}</Subheading>
        <Text>{t('change_password.description')}</Text>
      </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(data => mutation.mutate(data))}
          className="space-y-6 sm:maw-w-xl"
        >

          {mutation.error && (
            <AlertBox
              error
              title={mutationError(mutation.error, {
                default: t('change_password.errors.default'),
                badRequest: t('change_password.errors.password_incorrect')
              })}
            />
          )}

          <FormField
            name="currentPassword"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('change_password.current_password')}</FormLabel>
                <div className="flex flex-row gap-2">
                  <FormControl>
                    <Input
                      type={hideCurrentPassword ? 'password' : 'text'}
                      required
                      autoComplete="current-password"
                      {...field}
                    />
                  </FormControl>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="outline"
                          size="icon"
                          type="button"
                          className="h-10 w-11"
                          onClick={() => setHideCurrentPassword(prev => !prev)}
                        >
                          {hideCurrentPassword
                            ? <EyeSlashIcon className="text-tertiary" />
                            : <EyeIcon className="text-tertiary" />}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{hideCurrentPassword ? t('general:show_pass') : t('general:hide_pass')}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            name="newPassword"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('change_password.new_password')}</FormLabel>
                <div className="flex flex-row gap-2">
                  <FormControl>
                    <Input
                      type={hideNewPassword ? 'password' : 'text'}
                      required
                      autoComplete="new-password"
                      {...field}
                    />
                  </FormControl>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="outline"
                          size="icon"
                          type="button"
                          className="h-10 w-11"
                          onClick={() => setHideNewPassword(prev => !prev)}
                        >
                          {hideNewPassword
                            ? <EyeSlashIcon className="text-tertiary" />
                            : <EyeIcon className="text-tertiary" />}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{hideNewPassword ? t('general:show_pass') : t('general:hide_pass')}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button type="submit" disabled={mutation.isPending}>
            {t('change_password.submit')}
          </Button>

        </form>
      </Form>

    </div>
  )
}
