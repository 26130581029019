import { captureException } from '@sentry/react'
import { AxiosError } from 'axios'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'
import { AlertBox } from './ui/alert-box'


export function ErrorBoundary({ error }: { error: unknown }) {
  const { t } = useTranslation([ 'general' ])
  const { pathname } = useLocation()

  // Log error to Sentry
  const errorId = useMemo(() => captureException(error), [ error ])

  // If the user is unauthenticated, redirect them to the login page
  if (error instanceof AxiosError && error.status === 401) {
    return (
      <Navigate to={`/login?redirect=${pathname}`} replace />
    )
  }

  // Warn about missing permission
  if (error instanceof AxiosError && error.status === 403) {
    return (
      <AlertBox
        warning
        title={t('warning_missing_permission')}
        description={t('warning_hint')}
        buttons={[
          { title: t('error_fix_reload'), onClick: () => location.reload() },
          { title: t('error_fix_back'), onClick: () => history.back() }
        ]}
      />
    )
  }

  // Warn about missing resource
  if (error instanceof AxiosError && error.status === 404) {
    return (
      <AlertBox
        warning
        title={t('warning_missing_resource')}
        description={t('warning_hint')}
        buttons={[
          { title: t('error_fix_reload'), onClick: () => location.reload() },
          { title: t('error_fix_back'), onClick: () => history.back() }
        ]}
      />
    )
  }

  // Show error otherwise
  return (
    <AlertBox
      error
      title={t('unexpected_error')}
      description={t('unexpected_error_hint', { errorId })}
      buttons={[
        { title: t('error_fix_reload'), onClick: () => location.reload() },
        { title: t('error_fix_back'), onClick: () => history.back() }
      ]}
    />
  )
}
