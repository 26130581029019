import { useTranslation } from 'react-i18next'
import { Text } from '~/components/ui/typography'


export default function MembersLoading() {
  const { t } = useTranslation([ 'team' ])

  return (
    <div className="w-full flex grow flex-col gap-4 items-start">

      <Text>{t('member_list.loading')}</Text>

    </div>
  )
}
