import { useTranslation } from 'react-i18next'
import { useRouteError } from 'react-router-dom'
import { ErrorBoundary } from '~/components/error-boundary'
import { Head } from '~/components/head'


export default function AccountError() {
  const { t } = useTranslation([ 'settings' ])
  const error = useRouteError()

  return (
    <div className="w-full">

      <Head title={t('account.page_title')} />

      <ErrorBoundary error={error} />

    </div>
  )
}
