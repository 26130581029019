import { ClipboardDocumentIcon, EllipsisVerticalIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import type { ClientAccount } from '~/api/client-accounts'
import { namespaceAPI } from '~/api/namespace'
import { Avatar, AvatarFallback } from '~/components/ui/avatar'
import { Button } from '~/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '~/components/ui/table'
import { Subheading, Text } from '~/components/ui/typography'
import { copy } from '~/lib/copy'
import { initials } from '~/lib/utils'


export function MembersSection() {
  const { t } = useTranslation([ 'customers', 'general' ])
  const params = useParams() as { organization: string; customer: string }
  const queryClient = useQueryClient()

  const { data: { members } } = useSuspenseQuery({
    queryKey: [ 'organizations', params.organization, 'customers', params.customer, 'members' ],
    queryFn: () => namespaceAPI.listMembers(params.customer, {
      pagination: {},
      sort: { by: 'email' }
    }),
    select: data => {
      data.members.forEach(member => {
        queryClient.setQueryData<ClientAccount>(
          [ 'organizations', params.organization, 'customers', params.customer, 'members', member.id ],
          member
        )
      })

      return data
    }
  })

  function copyMemberEmail(member: ClientAccount) {
    copy(member.email)
    toast.info(t('member_list.email_copied'))
  }

  return (
    <>

      <div className="flex flex-row w-full justify-between items-center">
        <div>
          <Subheading>{t('member_list.title')}</Subheading>
          <Text>{t('member_list.description')}</Text>
        </div>
        <Link to='./members/new'>
          <Button variant="ghost">
            <PlusIcon />
            {t('member_list.new_member')}
          </Button>
        </Link>
      </div>

      {members.length === 0 ? (
        <Text className="italic">{t('member_list.empty')}</Text>
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t('member_list.table.name')}</TableHead>
              <TableHead>{t('member_list.table.email')}</TableHead>
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            {members.map(member => (
              <TableRow key={member.id}>
                <TableCell className="font-medium">
                  <div className="flex flex-row items-center gap-4">
                    <Avatar>
                      <AvatarFallback>{initials(member.properties.fullname)}</AvatarFallback>
                    </Avatar>
                    <Subheading>{member.properties.fullname}</Subheading>
                  </div>
                </TableCell>
                <TableCell>
                  {member.email}
                </TableCell>
                <TableCell className="text-right flex flex-row justify-end">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="icon">
                        <EllipsisVerticalIcon />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuLabel>{t('member_list.table.menu.label')}</DropdownMenuLabel>
                      <Link to={`./members/${member.id}/remove`}>
                        <DropdownMenuItem>
                          <XMarkIcon className="text-tertiary" />
                          {t('member_list.table.menu.remove')}
                        </DropdownMenuItem>
                      </Link>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem onClick={() => copyMemberEmail(member)}>
                        <ClipboardDocumentIcon className="text-tertiary" />
                        {t('member_list.table.menu.copy_email')}
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

    </>
  )
}
