import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { accountAPI } from '~/api/account'
import { Head } from '~/components/head'
import { AlertBox } from '~/components/ui/alert-box'
import { Button } from '~/components/ui/button'
import { Checkbox } from '~/components/ui/checkbox'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { mutationError } from '~/lib/mutation-error'


interface FormInputs {
  email: string
  password: string
  remember?: boolean
}

export default function LoginPage() {
  const { t } = useTranslation([ 'auth', 'general' ])
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [ searchParams ] = useSearchParams()

  // Get the optional redirect url
  const redirectUrl = searchParams.get('redirect')

  // Form state
  const form = useForm<FormInputs>({
    defaultValues: {
      email: localStorage.getItem('remembered_email') ?? '',
      password: '',
      remember: true
    }
  })

  // Log the user in
  const mutation = useMutation({
    mutationKey: [ 'auth', 'sign-in' ],
    mutationFn: (inputs: FormInputs) => accountAPI.signIn(inputs.email, inputs.password),
    onSuccess: (_, inputs) => {
      if (inputs.remember) localStorage.setItem('remembered_email', inputs.email)
      queryClient.clear()
      navigate(redirectUrl ?? '/', { replace: !!redirectUrl })
    }
  })

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">

      <Head title={t('login.page_title')} />

      <div className="sm:mx-auto sm:w-full sm:max-w-sm -mt-8">
        <Link to="../login">
          <img
            src="https://cleverev.net/assets/logo.svg"
            alt={t('general:logo_alt')}
            className="mx-auto h-12 w-auto"
          />
        </Link>
        <h1 className="mt-10 mb-10 text text-xl text-center font-bold">
          {t('login.heading')}
        </h1>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(data => mutation.mutate(data))} className="space-y-6">

          {mutation.error && (
            <AlertBox
              error
              title={mutationError(mutation.error, {
                default: t('login.errors.default'),
                badRequest: t('login.errors.invalid_credentials')
              })}
            />
          )}

          <FormField
            name="email"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('login.email')}</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    autoFocus
                    autoComplete="email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            name="password"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('login.password')}</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    autoComplete="current-password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex items-center justify-between">

            {/* Remember me */}

            <FormField
              name="remember"
              control={form.control}
              render={({ field }) => (
                <FormItem className="flex flex-row gap-4 items-center">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel className="!mt-0 font-normal">{t('login.remember_me')}</FormLabel>
                </FormItem>
              )}
            />

            <Link to="../forgot-password" className="text-sm font-medium">
              {t('login.to_forgot_password')}
            </Link>

          </div>

          <Button
            type="submit"
            className="w-full"
            disabled={mutation.isSuccess || mutation.isPending}
          >
            {t('login.submit')}
          </Button>

        </form>
      </Form>

    </div>
  )
}
