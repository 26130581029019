import { useTranslation } from 'react-i18next'
import { Head } from '~/components/head'
import { Text } from '~/components/ui/typography'


export default function AppearanceLoading() {
  const { t } = useTranslation([ 'settings' ])

  return (
    <div className="w-full">

      <Head title={t('appearance.page_title')} />

      <Text>{t('appearance.loading')}</Text>

    </div>
  )
}
