import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { invitationAPI } from '~/api/invitation'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '~/components/ui/alert-dialog'
import { mutationError } from '~/lib/mutation-error'


export default function CancelInvitationModal() {
  const { t } = useTranslation([ 'team', 'general' ])
  const navigate = useNavigate()
  const params = useParams() as { organization: string; invitation: string }
  const queryClient = useQueryClient()

  // Close the modal by navigating away
  const close = () => navigate('..')

  // Fetch the invitation
  const { data: { email } } = useSuspenseQuery({
    queryKey: [ 'organizations', params.organization, 'invitations', params.invitation ],
    queryFn: () => invitationAPI.get(params.invitation)
  })

  // Cancel the invitation
  const mutation = useMutation({
    mutationKey: [ 'organizations', params.organization, 'invitations', params.invitation, 'cancel' ],
    mutationFn: () => invitationAPI.delete(params.invitation),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ 'organizations', params.organization, 'invitations' ]
      })
      toast.success(t('cancel_invitation.success', { email }))
    },
    onError: error => {
      toast.error(mutationError(error, {
        default: t('cancel_invitation.errors.default'),
        forbidden: t('cancel_invitation.errors.forbidden')
      }))
    },
    onSettled: () => close()
  })

  return (
    <AlertDialog open>
      <AlertDialogContent onEscapeKeyDown={close}>
        <AlertDialogHeader>
          <AlertDialogTitle>{t('cancel_invitation.title', { email })}</AlertDialogTitle>
          <AlertDialogDescription>{t('cancel_invitation.description')}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={close}>
            {t('general:cancel')}
          </AlertDialogCancel>
          <AlertDialogAction onClick={() => mutation.mutate()} disabled={mutation.isPending}>
            {t('cancel_invitation.submit')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
