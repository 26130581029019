import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '~/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '~/components/ui/dialog'
import { namespaceAPI } from '~/api/namespace'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { AlertBox } from '~/components/ui/alert-box'
import { mutationError } from '~/lib/mutation-error'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip'
import { toast } from 'sonner'


interface FormInputs {
  email: string
  password: string
  firstName: string
  lastName: string
}

export default function NewMemberModal() {
  const { t } = useTranslation([ 'customers', 'general' ])
  const params = useParams() as { organization: string; customer: string }
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [ hidePassword, setHidePassword ] = useState(true)

  const close = () => navigate('..')

  const form = useForm<FormInputs>({
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: ''
    }
  })

  const mutation = useMutation({
    mutationKey: [ 'organizations', params.organization, 'customers', params.customer, 'members', 'create' ],
    mutationFn: (inputs: FormInputs) => namespaceAPI.createMember(params.customer, inputs),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'organizations', params.organization, 'customers', params.customer, 'members' ] })
      toast.success(t('new_member.success'))
      navigate('..')
    }
  })

  return (
    <Dialog open modal onOpenChange={close}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('new_member.title')}</DialogTitle>
          <DialogDescription>{t('new_member.description')}</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(inputs => mutation.mutate(inputs))}
            className="flex flex-col gap-4"
          >

            {mutation.error && (
              <AlertBox
                error
                title={mutationError(mutation.error, {
                  default: t('new_member.errors.default'),
                  forbidden: t('new_member.errors.forbidden')
                })}
              />
            )}

            <FormField
              name="email"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('new_member.email')}</FormLabel>
                  <FormControl>
                    <Input
                      type="email"
                      required
                      autoFocus
                      autoComplete="email"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex flex-row gap-8">

              <FormField
                name="firstName"
                control={form.control}
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>{t('new_member.first_name')}</FormLabel>
                    <FormControl>
                      <Input
                        required
                        maxLength={64}
                        autoComplete="given-name"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="lastName"
                control={form.control}
                render={({ field }) => (
                  <FormItem className="w-full">
                    <FormLabel>{t('new_member.last_name')}</FormLabel>
                    <FormControl>
                      <Input
                        required
                        maxLength={64}
                        autoComplete="family-name"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

            </div>

            <FormField
              name="password"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('new_member.password')}</FormLabel>
                  <div className="flex flex-row gap-2">
                    <FormControl>
                      <Input
                        type={hidePassword ? 'password' : 'text'}
                        required
                        autoComplete="new-password"
                        {...field}
                      />
                    </FormControl>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="outline"
                            size="icon"
                            type="button"
                            className="h-10 w-11"
                            onClick={() => setHidePassword(prev => !prev)}
                          >
                            {hidePassword
                              ? <EyeSlashIcon className="text-tertiary" />
                              : <EyeIcon className="text-tertiary" />}
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{hidePassword ? t('new_member.show_pass') : t('new_member.hide_pass')}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter className="gap-2 justify-start">
              <Button type="button" variant="outline" onClick={close}>
                {t('general:cancel')}
              </Button>
              <Button type="submit" disabled={mutation.isPending}>
                {t('new_member.submit')}
              </Button>
            </DialogFooter>

          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
