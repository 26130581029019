import { useSuspenseQuery } from '@tanstack/react-query'
import { accountAPI } from '~/api/account'


export function useAuth() {
  const { data: account } = useSuspenseQuery({
    queryKey: [ 'auth', 'account' ],
    queryFn: () => accountAPI.getSelfSafe()
  })

  return { account }
}
