import {
  ArrowRightStartOnRectangleIcon,
  BriefcaseIcon,
  BuildingOffice2Icon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
  HomeIcon,
  LifebuoyIcon,
  UserGroupIcon,
  UserPlusIcon
} from '@heroicons/react/20/solid'
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useMatch, useNavigate, useParams } from 'react-router-dom'
import { Avatar, AvatarFallback } from '~/components/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider
} from '~/components/ui/sidebar'
import { accountAPI } from '~/api/account'
import { organizationAPI } from '~/api/organization'
import { initials } from '~/lib/utils'
import { OrganizationSelector } from '~/components/organization-selector'


export default function OrganizationLayout() {
  const { t } = useTranslation([ 'general' ])
  const params = useParams() as { organization: string }
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  // Organization selector state
  const [ isOpen, setIsOpen ] = useState(false)

  // Check for matching sidebar items
  const matchesHome = useMatch('/:organization')
  const matchesPartners = useMatch('/:organization/partners/*')
  const matchesCustomers = useMatch('/:organization/customers/*')
  const matchesTeamMembers = useMatch('/:organization/team/members/*')
  const matchesTeamInvitations = useMatch('/:organization/team/invitations/*')
  const matchesSupport = useMatch('/:organization/support/*')

  // Fetch account
  const { data: account } = useSuspenseQuery({
    queryKey: [ 'account' ],
    queryFn: () => accountAPI.getSelf()
  })

  // Fetch the organization
  const { data: organization } = useSuspenseQuery({
    queryKey: [ 'organizations', params.organization ],
    queryFn: () => organizationAPI.get(params.organization)
  })

  // Sign out
  const signOutMutation = useMutation({
    mutationKey: [ 'account', 'sign-out' ],
    mutationFn: () => accountAPI.signOut(),
    onSuccess: () => {
      queryClient.clear()
      navigate('/login')
    }
  })

  return (
    <SidebarProvider>

      <Sidebar variant="inset">

        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton size="lg" onClick={() => setIsOpen(true)}>
                <div className="flex aspect-square size-8 items-center justify-center rounded-lg">
                  <img
                    src="https://cleverev.net/assets/logo.svg"
                    alt={t('logo_alt')}
                    className="h-8 w-8"
                  />
                </div>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">{organization.name}</span>
                  <span className="truncate text-xs">{organization.shortId}</span>
                </div>
                <ChevronUpDownIcon className="text-tertiary" />
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>

        <OrganizationSelector open={isOpen} onOpenChange={setIsOpen} />

        <SidebarContent>
          <SidebarGroup>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton asChild isActive={!!matchesHome}>
                  <Link to={`/${organization.shortId}`}>
                    <HomeIcon className="text-tertiary" />
                    <span className="font-medium">{t('sidebar.home')}</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton asChild isActive={!!matchesPartners}>
                  <Link to={`/${organization.shortId}/partners`}>
                    <BriefcaseIcon className="text-tertiary" />
                    <span className="font-medium">{t('sidebar.partners')}</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton asChild isActive={!!matchesCustomers}>
                  <Link to={`/${organization.shortId}/customers`}>
                    <BuildingOffice2Icon className="text-tertiary" />
                    <span className="font-medium">{t('sidebar.customers')}</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroup>

          <SidebarGroup>
            <SidebarGroupLabel className="font-bold">{t('sidebar.team')}</SidebarGroupLabel>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton asChild isActive={!!matchesTeamMembers}>
                  <Link to={`/${organization.shortId}/team/members`}>
                    <UserGroupIcon className="text-tertiary" />
                    <span className="font-medium">{t('sidebar.members')}</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton asChild isActive={!!matchesTeamInvitations}>
                  <Link to={`/${organization.shortId}/team/invitations`}>
                    <UserPlusIcon className="text-tertiary" />
                    <span className="font-medium">{t('sidebar.invitations')}</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroup>

          <SidebarGroup className="mt-auto">
            <SidebarGroupContent>
              <SidebarMenu>
                <SidebarMenuItem>
                  <SidebarMenuButton asChild isActive={!!matchesSupport}>
                    <Link to={`/${organization.shortId}/support`}>
                      <LifebuoyIcon className="text-tertiary" />
                      <span className="font-medium">{t('sidebar.support')}</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>

        <SidebarFooter>
          <SidebarMenu>
            <SidebarMenuItem>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <SidebarMenuButton
                    size="lg"
                    className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                  >
                    <Avatar className="h-8 w-8 rounded-lg">
                      <AvatarFallback className="rounded-lg bg-secondary">
                        {initials(`${account.firstName} ${account.lastName}`)}
                      </AvatarFallback>
                    </Avatar>
                    <div className="grid flex-1 text-left text-sm leading-tight">
                      <span className="truncate font-semibold">
                        {account.firstName} {account.lastName}
                      </span>
                      <span className="truncate text-xs">
                        {account.email}
                      </span>
                    </div>
                    <ChevronUpIcon className="text-tertiary ml-auto size-4" />
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                  side="bottom"
                  align="end"
                  sideOffset={4}
                >
                  <Link to={`/${organization.shortId}/settings/account`}>
                    <DropdownMenuItem>
                      <Cog6ToothIcon className="text-tertiary" />
                      {t('sidebar.settings')}
                    </DropdownMenuItem>
                  </Link>
                  <DropdownMenuItem onClick={() => signOutMutation.mutate()}>
                    <ArrowRightStartOnRectangleIcon className="text-tertiary" />
                    {t('sidebar.log_out')}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>

      </Sidebar>

      <SidebarInset>

        {/* <header className="flex h-16 shrink-0 items-center gap-2">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger className="-ml-1" />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem className="hidden md:block">
                  <BreadcrumbLink href="#">
                    Building Your Application
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator className="hidden md:block" />
                <BreadcrumbItem>
                  <BreadcrumbPage>Data Fetching</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </div>
        </header> */}

        <main className="flex flex-1 flex-col gap-4 p-8 px-12">
          <Outlet />
        </main>

      </SidebarInset>
    </SidebarProvider>
  )
}
