import { PlusIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useMatch } from 'react-router-dom'
import { Separator } from '~/components/ui/separator'
import { Navbar, NavbarItem, NavbarSection } from '~/components/ui/navbar'
import { Heading, Text } from '~/components/ui/typography'
import { Button } from '~/components/ui/button'
import { Head } from '~/components/head'


export default function TeamLayout() {
  const { t } = useTranslation([ 'team' ])

  // Check for matching tab pathnames
  const matchesTeamMembers = useMatch('/:organization/team/members/*')
  const matchesTeamInvitations = useMatch('/:organization/team/invitations/*')

  return (
    <div className="flex grow flex-col gap-4 items-start">

      <Head title={t('page_title')} />

      <div className="flex w-full items-end justify-between gap-4">
        <Heading>{t('header_title')}</Heading>
        <Link to="./team/invitations/new">
          <Button>
            <PlusIcon />
            {t('action_invite_member')}
          </Button>
        </Link>
      </div>

      <Text>{t('header_description')}</Text>

      <Separator />

      <Navbar>
        <NavbarSection>
          <NavbarItem to="./team/members" current={!!matchesTeamMembers}>
            {t('tabs.members')}
          </NavbarItem>
          <NavbarItem to="./team/invitations" current={!!matchesTeamInvitations}>
            {t('tabs.invitations')}
          </NavbarItem>
        </NavbarSection>
      </Navbar>

      <Outlet />

    </div>
  )
}
