'use client'

import { LayoutGroup, motion } from 'framer-motion'
import React, { forwardRef, useId } from 'react'
import { Button, type ButtonProps } from './button'
import { cn } from '~/lib/utils'
import { Link } from 'react-router-dom'

export function Navbar({ className, ...props }: React.ComponentPropsWithoutRef<'nav'>) {
  return <nav {...props} className={cn(className, 'flex items-center gap-4 py-2.5')} />
}

export function NavbarDivider({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
  return <div aria-hidden="true" {...props} className={cn(className, 'h-6 w-px bg-zinc-950/10 dark:bg-white/10')} />
}

export function NavbarSection({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
  const id = useId()

  return (
    <LayoutGroup id={id}>
      <div {...props} className={cn(className, 'flex items-center gap-3')} />
    </LayoutGroup>
  )
}

export function NavbarSpacer({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
  return <div aria-hidden="true" {...props} className={cn(className, '-ml-4 flex-1')} />
}

export const NavbarItem = forwardRef(function NavbarItem(
  {
    current,
    className,
    children,
    to,
    ...props
  }: { to: string; current?: boolean; className?: string; children: React.ReactNode } & (
    | Omit<ButtonProps, 'as' | 'className'>
  ),
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <span className={cn(className, 'relative')}>
      {current && (
        <motion.span
          layoutId="current-indicator"
          className="absolute inset-x-2 -bottom-2.5 h-0.5 rounded-full bg-primary"
        />
      )}
      <Link to={to}>
        <Button
          {...props}
          variant="ghost"
          data-current={current ? 'true' : undefined}
          ref={ref}
        >
          {children}
        </Button>
      </Link>
    </span>
  )
})

export function NavbarLabel({ className, ...props }: React.ComponentPropsWithoutRef<'span'>) {
  return <span {...props} className={cn(className, 'truncate')} />
}
