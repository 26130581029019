export const config = {
  pageTitle: 'CleverEV Hub',
  // Legal
  legal: {
    eulaUrl: 'https://cleverev.net/terms/',
    tosUrl: 'https://cleverev.net/documents/eula/'
  },
  // Sentry
  sentry: {
    org: 'comment-team',
    project: 'cleverev-hub',
    url: 'https://sentry.cleverev.net',
    dsn: 'https://88010c0a35d4356d7baf3aa2e227faa6@sentry.cleverev.net/19'
  }
}
