import { ClipboardDocumentIcon, EllipsisVerticalIcon, LockClosedIcon, PlusIcon, TrashIcon } from '@heroicons/react/20/solid'
import { FolderPlusIcon } from '@heroicons/react/24/outline'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { type MembershipExpanded } from '~/api/membership'
import { organizationAPI } from '~/api/organization'
import { EmptyState } from '~/components/empty-state'
import { Button } from '~/components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '~/components/ui/table'
import { Subheading } from '~/components/ui/typography'
import { Avatar, AvatarFallback } from '~/components/ui/avatar'
import { initials } from '~/lib/utils'
import { Badge } from '~/components/ui/badge'
import { formatDateTime2Digit } from '~/lib/datetime'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'
import { copy } from '~/lib/copy'


export default function MembersPage() {
  const { t, i18n } = useTranslation([ 'team' ])
  const params = useParams() as { organization: string }
  const queryClient = useQueryClient()

  // Fetch the team members
  const { data: { memberships, totalCount } } = useSuspenseQuery({
    queryKey: [ 'organizations', params.organization, 'memberships' ],
    queryFn: () => organizationAPI.listMemberships(params.organization, {}),
    select: data => {
      data.memberships.forEach(membership => {
        queryClient.setQueryData<MembershipExpanded>(
          [ 'organizations', params.organization, 'memberships', membership.id ],
          membership
        )
      })

      return data
    }
  })

  // Copy member email and notify
  function copyMemberEmail(membership: MembershipExpanded) {
    copy(membership.account.email)
    toast.info(t('member_list.email_copied'))
  }

  return (
    <div className="w-full flex grow flex-col gap-4 items-start">

      <Outlet />

      {totalCount === 0 ? (
        <EmptyState
          title={t('member_list.empty_title')}
          description={t('member_list.empty_description')}
          Icon={FolderPlusIcon}
        >
          <Link to="../invitations/new">
            <Button>
              <PlusIcon />
              {t('action_invite_member')}
            </Button>
          </Link>
        </EmptyState>
      ) : (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t('member_list.table.name')}</TableHead>
                <TableHead>{t('member_list.table.email')}</TableHead>
                <TableHead>{t('member_list.table.access')}</TableHead>
                <TableHead>{t('member_list.table.since')}</TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody>
              {memberships.map(membership => (
                <TableRow key={membership.id}>
                  <TableCell className="font-medium">
                    <div className="flex flex-row items-center gap-4">
                      <Avatar>
                        <AvatarFallback>
                          {initials(`${membership.account.firstName} ${membership.account.lastName}`)}
                        </AvatarFallback>
                      </Avatar>
                      <Subheading>
                        {`${membership.account.firstName} ${membership.account.lastName}`}
                      </Subheading>
                    </div>
                  </TableCell>
                  <TableCell>
                    {membership.account.email}
                  </TableCell>
                  <TableCell>
                    {membership.admin
                      ? <Badge color="pink">{t('fields.level_enum.admin').toLowerCase()}</Badge>
                      : <Badge color="sky">{t('fields.level_enum.member').toLowerCase()}</Badge>
                    }
                  </TableCell>
                  <TableCell>
                    {formatDateTime2Digit(membership.createdAt, i18n.language)}
                  </TableCell>
                  <TableCell className="text-right flex flex-row justify-end">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="icon">
                          <EllipsisVerticalIcon />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuLabel>{t('member_list.menu.label')}</DropdownMenuLabel>
                        <Link to={`./${membership.id}/change-access`}>
                          <DropdownMenuItem>
                            <LockClosedIcon className="text-tertiary" />
                            {t('member_list.menu.change_access')}
                          </DropdownMenuItem>
                        </Link>
                        <Link to={`./${membership.id}/remove`}>
                          <DropdownMenuItem>
                            <TrashIcon className="text-tertiary" />
                            {t('member_list.menu.remove')}
                          </DropdownMenuItem>
                        </Link>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={() => copyMemberEmail(membership)}>
                          <ClipboardDocumentIcon className="text-tertiary" />
                          {t('member_list.menu.copy_email')}
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}

    </div>
  )
}
