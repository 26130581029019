import { useRouteError } from 'react-router-dom'
import { ErrorBoundary } from '~/components/error-boundary'


export default function MembersError() {
  const error = useRouteError()

  return (
    <div className="w-full flex grow flex-col gap-4 items-start">

      <ErrorBoundary error={error} />

    </div>
  )
}
