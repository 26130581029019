import {
  ArrowRightEndOnRectangleIcon,
  ClipboardDocumentIcon,
  EllipsisVerticalIcon
} from '@heroicons/react/20/solid'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { organizationAPI, type Organization } from '~/api/organization'
import { Head } from '~/components/head'
import { Button } from '~/components/ui/button'
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm
} from '~/components/ui/description-list'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'
import { Separator } from '~/components/ui/separator'
import { Heading, Subheading, Text } from '~/components/ui/typography'
import { copy } from '~/lib/copy'
import { formatDateTime } from '~/lib/datetime'


export default function PartnerPage() {
  const { t, i18n } = useTranslation([ 'partners', 'general' ])
  const params = useParams() as { organization: string; partner: string }

  // Fetch the partner organization
  const { data: partner } = useSuspenseQuery({
    queryKey: [ 'organizations', params.organization, 'partners', params.partner ],
    queryFn: () => organizationAPI.get(params.partner)
  })

  // Copy partner id and notify
  function copyPartnerId(partner: Organization) {
    copy(partner.shortId)
    toast.info(t('view_partner.id_copied'))
  }

  return (
    <div className="flex grow flex-col gap-4 items-start">

      <Head title={partner.name} />

      <Outlet />

      <div className="flex w-full items-end justify-between gap-4">
        <Heading>{partner.name}</Heading>
        <div className="flex flex-row gap-4">
          <Link to={`/${partner.shortId}`}>
            <Button variant="ghost">
              <ArrowRightEndOnRectangleIcon className="text-tertiary" />
              {t('view_partner.switch_to')}
            </Button>
          </Link>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button variant="ghost" size="icon">
                <EllipsisVerticalIcon className="text-tertiary" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => copyPartnerId(partner)}>
                <ClipboardDocumentIcon className="text-tertiary" />
                {t('view_partner.menu.copy_id')}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <Separator />

      <Subheading>{t('view_partner.details')}</Subheading>

      <DescriptionList className="w-full">
        <DescriptionTerm>
          {t('fields.name')}
        </DescriptionTerm>
        <DescriptionDetails>
          {partner.name}
        </DescriptionDetails>
        <DescriptionTerm>
          {t('fields.id')}
        </DescriptionTerm>
        <DescriptionDetails className="flex gap-2 items-center">
          {partner.shortId}
        </DescriptionDetails>
        <DescriptionTerm>
          {t('fields.created_at')}
        </DescriptionTerm>
        <DescriptionDetails>
          {formatDateTime(partner.createdAt, i18n.language)}
        </DescriptionDetails>
      </DescriptionList>

      <Subheading>{t('view_partner.members')}</Subheading>

      <Text>{t('general:wip')}</Text>

    </div>
  )
}
