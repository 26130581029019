import { useTranslation } from 'react-i18next'
import { Head } from '~/components/head'
import { Button } from '~/components/ui/button'
import { Separator } from '~/components/ui/separator'
import { Heading, Text } from '~/components/ui/typography'


export default function SupportPage() {
  const { t } = useTranslation([ 'support', 'general' ])

  return (
    <div className="flex grow flex-col gap-4 items-start">

      <Head title={t('page_title')} />

      <div className="flex w-full items-end justify-between gap-4">
        <Heading>{t('header_title')}</Heading>
        <Button className="invisible" />
      </div>

      <Text>{t('header_description')}</Text>

      <Separator />

      <Text>{t('general:wip')}</Text>

    </div>
  )
}
