import { ArrowRightStartOnRectangleIcon, ClipboardDocumentIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link, Outlet } from 'react-router-dom'
import { toast } from 'sonner'
import { accountAPI } from '~/api/account'
import { type Organization } from '~/api/organization'
import { EmptyState } from '~/components/empty-state'
import { Head } from '~/components/head'
import { Avatar, AvatarFallback } from '~/components/ui/avatar'
import { Badge } from '~/components/ui/badge'
import { Button } from '~/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '~/components/ui/dropdown-menu'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '~/components/ui/table'
import { Subheading } from '~/components/ui/typography'
import { copy } from '~/lib/copy'
import { formatDateTime2Digit } from '~/lib/datetime'
import { initials } from '~/lib/utils'


export default function OrganizationsPage() {
  const { t, i18n } = useTranslation([ 'settings' ])
  const queryClient = useQueryClient()

  // Fetch account
  const { data: account } = useSuspenseQuery({
    queryKey: [ 'account' ],
    queryFn: () => accountAPI.getSelf()
  })

  // Fetch the account's memberships
  const { data: { memberships, totalCount } } = useSuspenseQuery({
    queryKey: [ 'account', 'memberships' ],
    queryFn: () => accountAPI.listMemberships(account.id, {
      sort: { by: 'createdAt', desc: false }
    }),
    select: data => {
      data.memberships.forEach(membership => (
        queryClient.setQueryData<Organization>(
          [ 'account', 'organizations', membership.organization.shortId ],
          membership.organization
        )
      ))

      return data
    }
  })

  // Copy organization id and notify
  function copyOrganizationId(organization: Organization) {
    copy(organization.shortId)
    toast.info(t('organization_list.id_copied'))
  }

  return (
    <div className="flex grow flex-col gap-4 items-start">

      <Head title={t('organization_list.page_title')} />

      <Outlet />

      {totalCount === 0 ? (
        <EmptyState
          title={t('organization_list.empty_title')}
          description={t('organization_list.empty_description')}
          Icon={QuestionMarkCircleIcon}
        />
      ) : (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t('organization_list.table.name')}</TableHead>
                <TableHead>{t('organization_list.table.id')}</TableHead>
                <TableHead>{t('organization_list.table.access')}</TableHead>
                <TableHead>{t('organization_list.table.joined_at')}</TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody>
              {memberships.map(membership => (
                <TableRow key={membership.id}>
                  <TableCell className="font-medium">
                    <div className="flex flex-row items-center gap-4">
                      <Avatar>
                        <AvatarFallback>{initials(membership.organization.name)}</AvatarFallback>
                      </Avatar>
                      <Subheading>{membership.organization.name}</Subheading>
                    </div>
                  </TableCell>
                  <TableCell>
                    {membership.organization.shortId}
                  </TableCell>
                  <TableCell>
                    {membership.admin
                      ? <Badge color="pink">{t('organization_list.access.admin').toLowerCase()}</Badge>
                      : <Badge color="sky">{t('organization_list.access.member').toLowerCase()}</Badge>
                    }
                  </TableCell>
                  <TableCell>{formatDateTime2Digit(membership.createdAt, i18n.language)}</TableCell>
                  <TableCell className="text-right flex flex-row justify-end">
                    <Link to={`./${membership.organization.shortId}/leave`}>
                      <Button variant="ghost" className="mr-4">
                        {t('organization_list.menu.leave')}
                      </Button>
                    </Link>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="icon">
                          <EllipsisVerticalIcon />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuLabel>{t('organization_list.menu.label')}</DropdownMenuLabel>
                        <Link to={`./${membership.organization.shortId}/leave`}>
                          <DropdownMenuItem>
                            <ArrowRightStartOnRectangleIcon className="text-tertiary" />
                            {t('organization_list.menu.leave')}
                          </DropdownMenuItem>
                        </Link>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={() => copyOrganizationId(membership.organization)}>
                          <ClipboardDocumentIcon className="text-tertiary" />
                          {t('organization_list.menu.copy_id')}
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}

    </div>
  )
}
