import { CheckIcon, LanguageIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'
import { Button } from '~/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'


export function LanguageSelector() {
  const { t } = useTranslation([ 'general' ])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="text-tertiary">
        <Button variant="ghost" size="icon">
          <LanguageIcon />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem>
          <span className="flex-1">{t('languages.en')}</span>
          <CheckIcon className="text-tertiary h-4 w-4" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
