import { useTranslation } from 'react-i18next'
import { Head } from '~/components/head'
import ProfileSection from './sections/profile-section'
import ChangePasswordSection from './sections/change-password-section'
import DeleteAccountSection from './sections/delete-account-section'
import VerifyEmailSection from './sections/verify-email-section'


export default function AccountPage() {
  const { t } = useTranslation([ 'settings' ])

  return (
    <>

      <Head title={t('account.page_title')} />

      <VerifyEmailSection />

      <div className="grow divide-y divide-tertiary/5">
        <ProfileSection />
        <ChangePasswordSection />
        <DeleteAccountSection />
      </div>

    </>
  )
}
