import { useTranslation } from 'react-i18next'
import { Outlet, useMatch } from 'react-router-dom'
import { Separator } from '~/components/ui/separator'
import { Navbar, NavbarItem, NavbarSection } from '~/components/ui/navbar'
import { Heading, Text } from '~/components/ui/typography'
import { Button } from '~/components/ui/button'


export default function SettingsLayout() {
  const { t } = useTranslation([ 'settings' ])

  // Check for matching tab pathnames
  const matchesAccount = useMatch('/:organization?/settings/account/*')
  const matchesAppearance = useMatch('/:organization?/settings/appearance/*')
  const matchesOrganizations = useMatch('/:organization?/settings/organizations/*')

  return (
    <div className="flex flex-1 flex-col gap-4">

      <div className="flex w-full items-end justify-between gap-4">
        <Heading>{t('header_title')}</Heading>
        <Button className="invisible" />
      </div>

      <Text>{t('header_description')}</Text>

      <Separator />

      <Navbar>
        <NavbarSection>
          <NavbarItem to="./settings/account" current={!!matchesAccount}>
            {t('tabs.account')}
          </NavbarItem>
          <NavbarItem to="./settings/appearance" current={!!matchesAppearance}>
            {t('tabs.appearance')}
          </NavbarItem>
          <NavbarItem to="./settings/organizations" current={!!matchesOrganizations}>
            {t('tabs.organizations')}
          </NavbarItem>
        </NavbarSection>
      </Navbar>

      <Outlet />

    </div>
  )
}
